import { action } from "mobx";
import { get, jsonParser } from "../utils/Client";

export const create = (store) => {
  return {
    list: [],
    category: { value: null, label: "" },
    initialDate: null,
    finalDate: null,
    size: 10,
    chartWidth: 0,
    chartHeight: 0,
    step: 100,
    loading: false,
    selectedUser: null,
    showSelectUserModal: false,
    total: 0,
    visibility: null,
    completeness: null,

    query: action(async () => {
        store.list = [];
        store.total = 0;
        var baseUrl = "";
        var type = null;
        store.loading = true;
        var queryVisibility = false;
        var queryCompleteness = false;

        switch(store.category.value){
          case "TOP_FERMENTABLES":
            baseUrl = "/fermentables/recipes/top";
            break;
          case "TOP_HOPS":
            baseUrl = "/hops/recipes/top";
            break;
          case "TOP_YEASTS":
            baseUrl = "/yeasts/recipes/top";
            break;
          case "TOP_VOLUMES":
            baseUrl = "/recipes/volumes/history";
            break;
          case "TOP_STYLES":
            baseUrl = "/beerstyles/recipes/top";
            break;
          case "TOP_BREW_STYLES":
            baseUrl = "/beerstyles/brews/top";
            break;
          case "RECIPE_CREATION_HISTORY":
            baseUrl = "/recipes/creation/history";
            queryVisibility = true;
            queryCompleteness = true;
            break;
          case "BREW_HISTORY":
            baseUrl = "/brews/creation/history";
            break;
          case "TOP_TEXT_CONTENT":
            baseUrl = "/contents/top";
            type = "TEXT";
            break;
          case "TOP_LIKED_RECIPES":
            baseUrl = "/likes/recipes/top";
            break;
          case "TOP_DISPLAYED_PROFILES":
            baseUrl = "/users/top";
            break;
          case "TOTAL_USERS":
            baseUrl = "/users/history";
            break;
          default:
            break;
        }

        await get(
          `${baseUrl}?size=${store.size}` +
          `${!!store.initialDate ? "&start=" + store.initialDate : ""}` +
          `${!!store.finalDate ? "&end=" + store.finalDate : ""}` + 
          `${!!type ? "&type=" + type : ""}` + 
          `${!!store.step ? "&step=" + store.step : ""}` + 
          `${!!store.selectedUser ? "&userId=" + store.selectedUser.id : ""}` + 
          `${(queryVisibility && !!store.visibility.value) ? "&visibility=" + store.visibility.value : ""}` +
          `${(queryCompleteness && !!store.completeness.value) ? "&completeness=" + store.completeness.value : ""}`
        )
        .then(jsonParser)
        .then((data) => {
          store.list = data;
          store.count = data.length ?? 0;
          if(store.category.showTotal){
            data.forEach(d => {
              store.total = store.total + d.value;
            });
          }
        })
        .catch(e => console.log(e.message ?? e))
        .finally(() => {
          store.loading = false;
        })
      }
    ),
  };
};

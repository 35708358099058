import React from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import {
  withWidth,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@material-ui/core";

import {
  Assignment,
  Home,
  Group,
  Tv,  
  ViewCarouselRounded,
  Announcement,
  EmojiEvents,
  LocalDrink,
} from "@material-ui/icons";

import AuthenticationStore from "../stores/AuthenticationStore";
import UIStore from "../stores/UIStore";

class AsideBar extends React.Component {
  navigate = url => {
    let { history } = this.props;
    history.push(url);
  };

  renderMenuRow = (telas, first) => {
    const { classes, location } = this.props;

    return(
      <div
        className={classNames(first ? classes.asideBar : classes.asideBarRow, {
          [classes.closed]: !UIStore.asideOpened && !UIStore.forceAside
        })}
      >
        {telas.map((t, i) =>
          t.to ? (
            <MenuItem
              key={i}
              selected={location.pathname === t.to}
              classes={{
                root: classes.menuItem,
                selected: classes.menuItemSelected
              }}
              onClick={() => this.navigate(t.to)}
              className={classes.customMenuItem}
            >
              <ListItemIcon
                className={classes.customListItemIcon}
              >
                {t.leftIcon}
              </ListItemIcon>
              <ListItemText
                inset
                primary={t.label}
                className={classes.customListItemText}
              />
            </MenuItem>
          ) : (
            <Divider key={i} />
          )
        )}
      </div>
    )
  }

  render() {
    let telas = [{ label: "Início", leftIcon: <Home />, to: "/" }];
    let telasRestristas = [
      { label: "Usuários", leftIcon: <Group />, to: "/users" },
      { label: "Conteúdos", leftIcon: <Tv />, to: "/contents" },
      { label: "Banners", leftIcon: <ViewCarouselRounded />, to: "/banners" },
      { label: "Denúncias", leftIcon: <Announcement />, to: "/forum-reports" },
      { label: "Ajuda", leftIcon: <Announcement />, to: "/help-requests" },
      { label: "Relatórios", leftIcon: <Assignment />, to: "/reports" },
      { label: "Exclusão de Contas", leftIcon: <Group />, to: "/account-deletion" },
      { label: "Estilos", leftIcon: <LocalDrink />, to: "/beer-styles" },
      { label: "Desafios", leftIcon: <EmojiEvents />, to: "/challenge-groups" },
      { label: "Rankings", leftIcon: <LocalDrink />, to: "/challenge-rankings" },
      { label: "Premium", leftIcon: <Group />, to: "/user-premium" },
      { label: "Copa", leftIcon: <EmojiEvents />, to: "/hops-cups" },
      { label: "Copa (Fazendas)", leftIcon: <EmojiEvents />, to: "/hops-cup-farms" },
      //{ label: "Verificações", leftIcon: <Person />, to: "/expert-role-requests" },
    ];
    let telasExpert = [
      { label: "Conteúdos", leftIcon: <Tv />, to: "/contents" },
      { label: "Relatórios", leftIcon: <Assignment />, to: "/reports" },
    ];

    if (AuthenticationStore.me.role === "ADMIN") {
      telas = telas.concat(telasRestristas);
    } else if(AuthenticationStore.me.role === "EXPERT"){
      telas = telas.concat(telasExpert);
    }

    return (
      <div>
        { this.renderMenuRow(telas.slice(0, 5), true) }
        { this.renderMenuRow(telas.slice(5, 9), false) }
        { this.renderMenuRow(telas.slice(9, 14), false) }
      </div>
    );
  }
}

const styles = theme => ({
  asideBar: {
    width: "65%",
    margin: "auto",
    marginTop: 125,
    marginBottom: -15,
    display: "flex",
    flex: 1,
    flexDirection: "row",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      marginTop: 40
    },
  },
  asideBarRow: {
    width: "65%",
    margin: "auto",
    marginTop: 40,
    marginBottom: -15,
    display: "flex",
    flex: 1,
    flexDirection: "row",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      marginTop: 40,
    },
  },
  menuItemSelected: {
    color: theme.palette.primary.main,
  },
  primary: {
    color: "#666"
  },
  customMenuItem: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 15,
    paddingLeft: 15,
    margin: "0 30px 0 0",
    borderRadius: 4,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  customListItemIcon: {
    padding: 0,
    margin: "-1px 10px 0 0",
    minWidth: "auto"
  },
  customListItemText: {
    padding: 0,
    margin: 0,  
    fontFamily: "Fira Sans",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

export default withRouter(withWidth()(withStyles(styles)(observer(AsideBar))));

import { observer } from "mobx-react";
import React from "react";
import { InputAdornment, Tab, Tabs } from "@material-ui/core";
import {
  Person,
  Email,
  VpnKey,
  Label,
  Phone,
} from "@material-ui/icons";
import TextField from "../../components/TextField";
import EditDialog from "../../components/EditDialog";
import CheckBox from "../../components/CheckBox";
import SelectField from "../../components/SelectField";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import { REQUIRED } from "../../utils/Validation";
import RecipeStats from "./RecipeStats";
import { get, jsonParser } from "../../utils/Client";
import { PhoneNumberUtil } from "google-libphonenumber";
const getCountryISO2 = require("country-iso-3-to-2");


const store = Stores.load("users");
const producerTypeOptions = [
  { label: "Cervejeiro", value: "BREWER" },
  { label: "Cervejaria", value: "BREWERY" },
];
const roles = [
  { label: "Administrador", value: "ADMIN" },
  { label: "Usuário", value: "USER" },
  { label: "Expert", value: "EXPERT" },
];

class UserEdit extends React.Component {
  state = {
    selectedTab: 0,
    countries: [],
  };

  loadCountries = async() => {
    try{
      const res = await get(`/countries?page=0&size=9999&sort=name&order=ASC`).then(jsonParser);
      const filtered = res.content.filter(c => !!getCountryISO2(c.isoCode));
      this.setState({ 
        countries: filtered.map(c => {
          const iso2 = getCountryISO2(c.isoCode);
          return { label: `${c.name}`, value: iso2 };
        }),
      })
    } catch(e){
      console.log(e);
    }
  }

  componentDidMount() {
    this.loadCountries();
    store.rules = {
      name: REQUIRED,
      email: REQUIRED,
      role: REQUIRED,
      phoneCountry: REQUIRED,
      phone: REQUIRED,
    };
  }

  save = () => {
    try{
      this.item.phone = this.item.phone.replace(new RegExp("[^0-9]", "g"), "");
      const phoneUtil = PhoneNumberUtil.getInstance();
      const phoneNumber = phoneUtil.parseAndKeepRawInput(this.item.phone, this.item.phoneCountry);
      const isValid = phoneUtil.isValidNumber(phoneNumber);
      if(!isValid){
        store.errors['phone'] = "O número de telefone é inválido";
        return;
      }
    } catch(e){
      console.log(e);
      store.errors['phone'] = "O número de telefone é inválido";
      return;
    }
    store
      .save(this.props.onSave)
      .then(() => UIStore.redo(store))
      .catch(UIStore.saveError);
  }

  render() {
    const { selectedTab, countries } = this.state;
    this.item = store.edit != null ? store.edit : {};

    return (
      <EditDialog
        title={this.item.id ? "Editar Usuário" : "Novo Usuário"}
        opened={store.edit != null}
        onCancel={() => (store.edit = null)}
        onSave={this.save}
      >
        <div>
          <div className="rowStyles" style={{ marginTop: -30 }}>
            <Tabs 
              value={selectedTab} 
              onChange={(event, value) => this.setState({ selectedTab: value })} 
              aria-label="user tabs"
            >
              <Tab label="Geral" value={0} />
              <Tab label="Receitas" value={1} />
            </Tabs>
          </div>
          { selectedTab === 0 ?
            <div className="rowStyles">
              <div className="columnStyles">
                <TextField
                  errors={store.errors}
                  field="name"
                  item={this.item}
                  label="Nome"
                  style={styles.inputOutline}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  errors={store.errors}
                  field="email"
                  item={this.item}
                  label="E-mail"
                  style={styles.inputOutline}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  errors={store.errors}
                  field="document"
                  item={this.item}
                  label="CPF/CNPJ"
                  style={styles.inputOutline}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Label color="primary" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={e => {
                    this.item.document = e.target.value.formatDocument();
                  }}
                />
                <SelectField
                  errors={store.errors}
                  field="phoneCountry"
                  item={this.item}
                  label="&nbsp; País origem do telefone"
                  list={countries}
                  style={styles.inputOutline}
                  variant="outlined"
                  onChange={(e) => {
                    this.item.phoneCountry = e.target.value;
                  }}
                />
                { !!this.item.phoneCountry &&
                  <TextField
                    errors={store.errors}
                    field="phone"
                    item={this.item}
                    label="Telefone (DDD + telefone)"
                    style={styles.inputOutline}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone color="primary" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={e => {
                      this.item.phone = e.target.value.replace(new RegExp("[^0-9]", "g"), "");
                      try{
                        const phoneUtil = PhoneNumberUtil.getInstance();
                        const phoneNumber = phoneUtil.parseAndKeepRawInput(this.item.phone, this.item.phoneCountry);
                        this.item.phone = phoneUtil.formatOutOfCountryCallingNumber(phoneNumber, this.item.phoneCountry);
                        const isValid = phoneUtil.isValidNumber(phoneNumber);
                        if(isValid){
                          delete store.errors['phone'];
                        } else{
                          store.errors['phone'] = "O número de telefone é inválido";
                        }
                      } catch(e){
                        console.log(e);
                        store.errors['phone'] = "O número de telefone é inválido";
                      }
                    }}
                  />
                }
                <TextField
                  errors={store.errors}
                  field="nick"
                  item={this.item}
                  label="Apelido"
                  style={styles.inputOutline}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Label color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  errors={store.errors}
                  field="password"
                  item={this.item}
                  label="Senha"
                  style={styles.inputOutline}
                  type="password"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKey color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
                <SelectField
                  errors={store.errors}
                  field="producerType"
                  item={this.item}
                  label="&nbsp; Tipo de conta"
                  list={producerTypeOptions}
                  style={styles.inputOutline}
                  variant="outlined"
                />
                <SelectField
                  errors={store.errors}
                  field="role"
                  item={this.item}
                  label="&nbsp; Tipo"
                  list={roles}
                  style={styles.inputOutline}
                  variant="outlined"
                />
                <div>
                  <CheckBox label="Liberado?" field="enabled" item={this.item} />
                </div>
              </div>
            </div>
            : selectedTab === 1 &&
            <RecipeStats user={this.item} />
          }
        </div>
      </EditDialog>
    );
  }
}

export default observer(UserEdit);

const styles = {
  inputOutline: {
    marginBottom: "20px",
  },
};

import React from "react";
import { observer } from "mobx-react";
import { Button } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";

import { SIZE } from "../utils/Constants";

class ListCrud extends React.Component {
  state = {
    value: "",
  };

  getRows = (data, store, status, onDelete, onEdit, onClick) =>
    data.map((field, i) => (
      <ListItem
        onClick={!!onClick ? () => onClick(field.item) : undefined}
        iconBox={{
          exists: field.icon != null,
          content: field.icon,
        }}
        key={i}
        content={field.content}
        important={field.important}
        title={field.title}
        status={status[i]}
        deleteAction={
          onDelete
            ? (e) => {
                store.selected = [field.item];
                onDelete(e);
              }
            : null
        }
        editAction={
          onEdit
            ? (e) => {
                store.selected = [field.item];
                onEdit(e);
              }
            : null
        }
      />
    ));

  handleNext = () => {
    let { store } = this.props;
    store.page++;
    store.query();
  };

  render() {
    let {
      data,
      onAdd,
      onDelete,
      onEdit,
      leftPanel,
      rightPanel,
      status,
      store,
      onClick,
    } = this.props;

    return (
      <div>
        <div style={styles.container} className="container">
          <div style={styles.subHeader} className="subHeader">
            <div style={styles.panelsStyle}>{leftPanel}</div>
            {rightPanel ? (
              <div style={styles.panelsStyle}>
                {rightPanel}
                {onAdd && (
                  <div style={styles.addLink} onClick={onAdd}>
                    <AddCircle color="primary" fontSize="large" />
                  </div>
                )}
              </div>
            ) : (
              onAdd && (
                <div style={styles.addLink} onClick={onAdd}>
                  <AddCircle color="primary" fontSize="large" />
                </div>
              )
            )}
          </div>
          {data.length > 0 && (
            <ul style={styles.dataList}>
              {this.getRows(data, store, status, onDelete, onEdit, onClick)}
            </ul>
          )}
          {store !== undefined &&
            store.page !== undefined &&
            data.length % SIZE === 0 &&
            data.length > 0 && (
              <Button onClick={this.handleNext}>Mais resultados...</Button>
            )}
        </div>
      </div>
    );
  }
}

const ListItem = ({
  iconBox,
  content,
  deleteAction,
  editAction,
  important,
  status,
  title,
  onClick,
}) => (
  <li 
    style={!onClick ? styles.userLi : styles.clickable} 
    className="userLi"
    onClick={!!onClick ? () => onClick() : undefined}
  >
    <div style={styles.iconBox}>
      {iconBox.exists && <>{iconBox.content}</>}
      <div className="liInfos">
        <h3 style={styles.liTitle}>{title}</h3>
        <p style={styles.liTexts}>{content}</p>
      </div>
    </div>
    <div style={styles.liActions} className="liActions">
      {important ? (
        <div
          style={{ ...styles.liActionsStatus, ...styles.importantStatus }}
          className={important.class}
        >
          {important.label}
        </div>
      ) : null}
      {status && (
        <div style={styles.liActionsStatus} className={status.class}>
          {status.label}
        </div>
      )}
      {deleteAction ? (
        <div
          style={styles.liActionsLink}
          onClick={deleteAction}
          className="liActionsLink"
        >
          <i className="fas fa-trash"></i>
        </div>
      ) : null}
      {editAction ? (
        <div
          style={styles.liActionsLink}
          onClick={editAction}
          className="liActionsLink"
        >
          <i className="fas fa-edit"></i>
        </div>
      ) : null}
    </div>
  </li>
);

export default observer(ListCrud);

const styles = {
  container: {
    width: "65%",
    margin: "auto",
    marginTop: 40,
    marginBottom: 105,
    border: "2px solid #ddf5f4",
    background: "#fff",
    bus: 4,
    padding: 20,
  },
  subHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  addLink: {
    cursor: "pointer",
    marginRight: -5,
  },
  dataList: {
    padding: 0,
    margin: 0,
  },
  userLi: {
    listStyleType: "none",
    borderTop: "1px solid #D2EDEC",
    marginTop: 18,
    paddingTop: 18,
    display: "flex",
    justifyContent: "space-between",
  },
  iconBox: {
    display: "flex",
  },
  liTitle: {
    margin: 0,
  },
  liTexts: {
    margin: 0,
  },
  liActions: {
    display: "flex",
    flexDirection: "row",
    height: 30,
  },
  liActionsLink: {
    fontSize: 16,
    color: "#999",
    cursor: "pointer",
    display: "inline-block",
    marginLeft: 20,
  },
  liActionsStatus: {
    height: "24px",
    lineHeight: "24px",
    display: "inline-block",
    fontWeight: "bold",
    color: "#fff",
    fontSize: 13,
    borderRadius: 4,
    padding: "0 7px 0 7px",
    marginTop: "-2px",
  },
  importantStatus: {
    marginRight: "5px",
  },
  panelsStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  clickable: {
    listStyleType: "none",
    borderTop: "1px solid #D2EDEC",
    marginTop: 18,
    paddingTop: 18,
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
  },
};

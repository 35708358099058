import { observer } from "mobx-react";
import React from "react";
import { baseUrl } from "../../utils/Client";
import ImageUpload from "../../components/ImageUpload";


class Awards extends React.Component {

  render(){
    const item = !!this.props.item ? this.props.item : {};

    return(
      <div className="rowStyles">
        <div style={styles.inputOutline}>
          <div style={styles.imgLabel}>
            {"Medalha de ouro (200x200 pixels)"}
          </div>
          <ImageUpload
            label="Ouro"
            value={!!item.firstHopImg
              ? item.firstHopImg
              : `${baseUrl}/img/hops-cup-first-hop/${item.id}/200/200?count=${item.hopImgCount}`
            }
            width={150}
            height={150}
            onChange={(photo) => (item.firstHopImg = photo)}
          />
        </div>
        <div style={styles.inputOutline}>
          <div style={styles.imgLabel}>
            {"Medalha de prata (200x200 pixels)"}
          </div>
          <ImageUpload
            label="Prata"
            value={!!item.secondHopImg
              ? item.secondHopImg
              : `${baseUrl}/img/hops-cup-second-hop/${item.id}/200/200?count=${item.hopImgCount}`
            }
            width={150}
            height={150}
            onChange={(photo) => (item.secondHopImg = photo)}
          />
        </div>
        <div style={styles.inputOutline}>
          <div style={styles.imgLabel}>
            {"Medalha de bronze (200x200 pixels)"}
          </div>
          <ImageUpload
            label="Bronze"
            value={!!item.thirdHopImg
              ? item.thirdHopImg
              : `${baseUrl}/img/hops-cup-third-hop/${item.id}/200/200?count=${item.hopImgCount}`
            }
            width={150}
            height={150}
            onChange={(photo) => (item.thirdHopImg = photo)}
          />
        </div>
      </div>
    )
  }
}

export default observer(Awards);

const styles = {
  imgLabel: {
    fontSize: "12px",
    color: "#777",
  },
  inputOutline: {
    marginBottom: "20px",
  },
}
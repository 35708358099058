import { observer } from "mobx-react";
import React from "react";
import {
  Delete,
  ExpandLess, 
  ExpandMore,
} from "@material-ui/icons";
import TextField from "../../components/TextField";
import { baseUrl } from "../../utils/Client";
import ImageUpload from "../../components/ImageUpload";
import { Radio } from "@material-ui/core";


const OIL_TYPE_LABELS = {
  "MYRCENE": "MYRCENE",
  "CARYOPHYLLENE": "CARYOPHYLLENE",
  "HUMULENE": "HUMULENE",
  "FARNESENE": "FARNESENE",
  "OTHERS": "OUTROS",
};

class HopCard extends React.Component {
  state = {
    open: false,
  }

  componentDidMount() {
    
  }

  formatRanking(value){
    value = value.replace(new RegExp("[^0-9]", "g"), "");
    if(value === ""){
      return "";
    }
    const n = parseInt(value);

    return Math.min(5, Math.max(0, n));
  }

  formatDecimalInput = (
    text,
    fractions = 2,
    allowsNegative = false
  ) => {
    if (!text) return "0." + "0".repeat(fractions);
    text = String(text);
    const negative = (text.match(/-/g) || []).length % 2;
    var base = Number(text.replace(/[^0-9]/g, "")).toString();
    if (text.length < fractions + 1) {
      base = "0".repeat(fractions - text.length + 1) + base;
    }
    const regex = new RegExp(`([0-9]{${fractions}})$`, "g");
    base = base.replace(regex, ".$1");
    if (base.length === fractions + 1) {
      base = "0" + base;
    }
    return (allowsNegative && negative ? "-" : "") + base;
  };

  render() {
    const { open } = this.state;
    const { 
      awardType,
      hop, 
      errors, 
      onChange, 
      onChangeType,
      onChangeOil,
      onDelete,
    } = this.props;
    
    return (
      <div style={styles.card}>
        <div style={styles.delete}>
          <div styles={{ flex: 1 }}/>
          <div 
            style={styles.deleteBtn}
            onClick={onDelete}
          >
            <Delete />
          </div>
        </div>
        { open ? (
            <>
              <div style={styles.inputOutline}>
                <div style={styles.imgLabel}>
                  {"Foto (200x200 pixels)"}
                </div>
                <ImageUpload
                  label="Foto"
                  value={!!hop.image
                      ? hop.image
                      : `${baseUrl}/img/hop/${hop.id}/300/300`
                  }
                  width={150}
                  height={150}
                  onChange={(photo) => onChange("image", photo)}
                />
              </div>
              <div style={styles.row}>
                <TextField
                  errors={errors}
                  field="citrus"
                  item={hop}
                  label="Citrus (0 a 5)"
                  placeholder="Citrus"
                  onChange={(e) => onChange("citrus", this.formatRanking(e.target.value))}
                  style={styles.inputOutline}
                  variant="outlined"
                />
                <TextField
                  errors={errors}
                  field="tropicalFruit"
                  item={hop}
                  label="Fruta Tropical (0 a 5)"
                  placeholder="Fruta Tropical"
                  onChange={(e) => onChange("tropicalFruit", this.formatRanking(e.target.value))}
                  style={styles.inputOutline}
                  variant="outlined"
                />
              </div>
              <div style={styles.row}>
                <TextField
                  errors={errors}
                  field="stoneFruit"
                  item={hop}
                  label="Fruta de Caroço (0 a 5)"
                  placeholder="Fruta de Caroço"
                  onChange={(e) => onChange("stoneFruit", this.formatRanking(e.target.value))}
                  style={styles.inputOutline}
                  variant="outlined"
                />
                <TextField
                  errors={errors}
                  field="berry"
                  item={hop}
                  label="Baga (0 a 5)"
                  placeholder="Baga"
                  onChange={(e) => onChange("berry", this.formatRanking(e.target.value))}
                  style={styles.inputOutline}
                  variant="outlined"
                />
              </div>
              <div style={styles.row}>
                <TextField
                  errors={errors}
                  field="floral"
                  item={hop}
                  label="Floral (0 a 5)"
                  placeholder="Floral"
                  onChange={(e) => onChange("floral", this.formatRanking(e.target.value))}
                  style={styles.inputOutline}
                  variant="outlined"
                />
                <TextField
                  errors={errors}
                  field="grassy"
                  item={hop}
                  label="Gramínea (0 a 5)"
                  placeholder="Gramínea"
                  onChange={(e) => onChange("grassy", this.formatRanking(e.target.value))}
                  style={styles.inputOutline}
                  variant="outlined"
                />
              </div>
              <div style={styles.row}>
                <TextField
                  errors={errors}
                  field="herbal"
                  item={hop}
                  label="Ervas (0 a 5)"
                  placeholder="Ervas"
                  onChange={(e) => onChange("herbal", this.formatRanking(e.target.value))}
                  style={styles.inputOutline}
                  variant="outlined"
                />
                <TextField
                  errors={errors}
                  field="spice"
                  item={hop}
                  label="Especiaria (0 a 5)"
                  placeholder="Especiaria"
                  onChange={(e) => onChange("spice", this.formatRanking(e.target.value))}
                  style={styles.inputOutline}
                  variant="outlined"
                />
              </div>
              <div style={styles.row}>
                <TextField
                  errors={errors}
                  field="resinPine"
                  item={hop}
                  label="Resina/Pinho (0 a 5)"
                  placeholder="Resina/Pinho"
                  onChange={(e) => onChange("resinPine", this.formatRanking(e.target.value))}
                  style={styles.inputOutline}
                  variant="outlined"
                />
                <div style={{ flex: 1 }} />
              </div>
              <div style={styles.row}>
                <TextField
                  errors={errors}
                  field="aa"
                  item={hop}
                  label="Alfa Ácidos (%)"
                  placeholder="Alfa Ácidos"
                  onChange={(e) => onChange("aa", this.formatDecimalInput(e.target.value))}
                  style={styles.inputOutline}
                  variant="outlined"
                />
                <TextField
                  errors={errors}
                  field="minAa"
                  item={hop}
                  label="Alfa Ácidos Mínimo (%)"
                  placeholder="Alfa Ácidos Mínimo"
                  onChange={(e) => onChange("minAa", this.formatDecimalInput(e.target.value))}
                  style={styles.inputOutline}
                  variant="outlined"
                />
                <TextField
                  errors={errors}
                  field="maxAa"
                  item={hop}
                  label="Alfa Ácidos Máximo (%)"
                  placeholder="Alfa Ácidos Máximo"
                  onChange={(e) => onChange("maxAa", this.formatDecimalInput(e.target.value))}
                  style={styles.inputOutline}
                  variant="outlined"
                />
              </div>
              <div style={styles.row}>
                <TextField
                  errors={errors}
                  field="ba"
                  item={hop}
                  label="Beta Ácidos (%)"
                  placeholder="Beta Ácidos"
                  onChange={(e) => onChange("ba", this.formatDecimalInput(e.target.value))}
                  style={styles.inputOutline}
                  variant="outlined"
                />
                <TextField
                  errors={errors}
                  field="minBa"
                  item={hop}
                  label="Beta Ácidos Mínimo (%)"
                  placeholder="Beta Ácidos Mínimo"
                  onChange={(e) => onChange("minBa", this.formatDecimalInput(e.target.value))}
                  style={styles.inputOutline}
                  variant="outlined"
                />
                <TextField
                  errors={errors}
                  field="maxBa"
                  item={hop}
                  label="Beta Ácidos Máximo (%)"
                  placeholder="Beta Ácidos Máximo"
                  onChange={(e) => onChange("maxBa", this.formatDecimalInput(e.target.value))}
                  style={styles.inputOutline}
                  variant="outlined"
                />
              </div>
              <div style={styles.row}>
                <TextField
                  errors={errors}
                  field="totalOils"
                  item={hop}
                  label="Óleos Totais (mL/100g)"
                  placeholder="Óleos Totais"
                  onChange={(e) => onChange("totalOils", this.formatDecimalInput(e.target.value))}
                  style={styles.inputOutline}
                  variant="outlined"
                />
                <TextField
                  errors={errors}
                  field="minOils"
                  item={hop}
                  label="Óleos Totais Mínimo (mL/100g)"
                  placeholder="Óleos Totais Mínimo"
                  onChange={(e) => onChange("minOils", this.formatDecimalInput(e.target.value))}
                  style={styles.inputOutline}
                  variant="outlined"
                />
                <TextField
                  errors={errors}
                  field="maxOils"
                  item={hop}
                  label="Óleos Totais Máximo (mL/100g)"
                  placeholder="Óleos Totais Máximo"
                  onChange={(e) => onChange("maxOils", this.formatDecimalInput(e.target.value))}
                  style={styles.inputOutline}
                  variant="outlined"
                />
              </div>
              { hop.oils?.map((o, index) =>
                <div key={`oil-${index}`}>
                  <div style={{ marginBottom: 5, marginTop: 10 }}>{OIL_TYPE_LABELS[o.type]}</div>
                  <div style={styles.row}>
                    <TextField
                      errors={errors}
                      field="totalOils"
                      item={hop.oils[index]}
                      label="Óleos Totais (mL/100g)"
                      placeholder="Óleos Totais"
                      onChange={(e) => onChangeOil(index, "totalOils", this.formatDecimalInput(e.target.value))}
                      style={styles.inputOutline}
                      variant="outlined"
                    />
                    <TextField
                      errors={errors}
                      field="minOils"
                      item={hop.oils[index]}
                      label="Óleos Totais Mínimo (mL/100g)"
                      placeholder="Óleos Totais Mínimo"
                      onChange={(e) => onChangeOil(index, "minOils", this.formatDecimalInput(e.target.value))}
                      style={styles.inputOutline}
                      variant="outlined"
                    />
                    <TextField
                      errors={errors}
                      field="maxOils"
                      item={hop.oils[index]}
                      label="Óleos Totais Máximo (mL/100g)"
                      placeholder="Óleos Totais Máximo"
                      onChange={(e) => onChangeOil(index, "maxOils", this.formatDecimalInput(e.target.value))}
                      style={styles.inputOutline}
                      variant="outlined"
                    />
                  </div>
                </div>
              )}
              <div
                onClick={() => this.setState({ open: false })}
                style={styles.showMore}
              >
                <ExpandLess /> 
              </div>
            </>
        ) : (
          <>
            <img 
              src={ !!hop.image 
                ? hop.image
                : `${baseUrl}/img/hop/${hop.id}/200/200?count=${hop.imageCount}`
              }
              alt="hop-img"
              style={{ borderRadius: 100, width: 100, height: 100, alignSelf: "center" }}
            />
            <div style={styles.cardValue}>
              {!!hop.title ? hop.title : "Sem nome"}
            </div>
            <div style={styles.awardLabel}>
              Premiação
            </div>
            <div style={styles.col}>
              <div style={styles.row}>
                <div style={styles.row2}>
                  <Radio
                    checked={awardType === 'GOLD'}
                    onChange={() => onChangeType("GOLD")}
                    value="GOLD"
                    name="radio-button-gold"
                    inputProps={{ 'aria-label': 'Ouro' }}
                  />
                  <div>Ouro</div>
                </div>
                <div style={styles.row2}>
                  <Radio
                    checked={awardType === 'SILVER'}
                    onChange={() => onChangeType("SILVER")}
                    value="SILVER"
                    name="radio-button-silver"
                    inputProps={{ 'aria-label': 'Prata' }}
                  />
                  <div>Prata</div>
                </div>
              </div>
              <div style={styles.row}>
                <div style={styles.row2}>
                  <Radio
                    checked={awardType === 'BRONZE'}
                    onChange={() => onChangeType("BRONZE")}
                    value="BRONZE"
                    name="radio-button-bronze"
                    inputProps={{ 'aria-label': 'Bronze' }}
                  />
                  <div>Bronze</div>
                </div>
                <div style={styles.row2}>
                  <Radio
                    checked={!awardType}
                    onChange={() => onChangeType(null)}
                    value={null}
                    name="radio-button-none"
                    inputProps={{ 'aria-label': 'Nenhum' }}
                  />
                  <div>Nenhum</div>
                </div>
              </div>
            </div>
            <div
              onClick={() => this.setState({ open: true })}
              style={styles.showMore}
            >
              <ExpandMore /> 
            </div>
          </>
          )
        }
      </div>
    );
  }
}

export default observer(HopCard);

const styles = {
  card: {
    backgroundColor: "#F2F2F2",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    paddingTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    minWidth: "300px",
  },
  inputOutline: {
    marginBottom: "20px",
  },
  cardValue: {
    fontWeight: "700",
    fontSize: "14px",
  },
  awardLabel: {
    fontWeight: "700",
    fontSize: "12px",
    marginTop: "10px",
  },
  showMore: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: "12px",
  },
  delete: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  deleteBtn: {
    cursor: "pointer",
    color: "#F00",
  },
  imgLabel: {
    fontSize: "12px",
    color: "#777",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 8,
  },
  row2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    gap: 8,
    flex: 1,
  },
  col: {
    display: "flex",
    flexDirection: "column",
  },
};

import React from "react";
import { observer } from "mobx-react";
import List from "../../components/List";
import AuthenticationStore from "../../stores/AuthenticationStore";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import Edit from "./Edit";
import SearchField from "../../components/SearchField";
import SortPanel from "../../components/SortPanel";
import SelectPanel from "../../components/SelectPanel";


const store = Stores.load("user-premium");
const PREMIUM_PLAN_TYPE = {
  FREE: "Gratuito",
  HOMEBREWER: "Caseiro",
  NANO_BREWERY: "Nano Cervejaria",
  MICRO_BREWERY: "Micro Cervejaria",
};
const PREMIUM_OPTIONS = [
  { label: "Somente Gratuito", value: "FREE" },
  { label: "Somente Caseiro", value: "HOMEBREWER" },
  { label: "Somente Nano Cervejaria", value: "NANO_BREWERY" },
  { label: "Somente Micro Cervejaria", value: "MICRO_BREWERY" },
];

class UserPremium extends React.Component {
  componentDidMount() {
    UIStore.init("Usuários", store);
  }

  handleDelete = () => {
    let count = store.selected.length;

    UIStore.showConfirmDialog(
      `Deseja remover ${count} usuários?`,
      "Remover Usuários"
    ).then((b) => {
      if (b) {
        store
          .delete()
          .then(() => store.query())
          .catch(() => alert("Erro!"));
      }
    });
  };

  handleFilter = () => {
    store.page = 0;
    store.searchAll = !store.searchAll;
    store.query();
  };

  render() {
    let canEdit;
    if (AuthenticationStore.me.role === "MANAGER") {
      canEdit = false;
    } else {
      canEdit = true;
    }

    return (
      <>
        <List
          store={store}
          data={store.list.map((i) => ({
            item: i,
            title: (!!i.name ? i.name : "Sem nome") + " (" + i.email + ")",
            content: (
              <>
                Papel: {i.role}
                <br />
                Tipo de plano:{" "}
                {!!i.premiumPlanType ? PREMIUM_PLAN_TYPE[i.premiumPlanType] : "Não definido"}
              </>
            ),
          }))}
          status={{}}
          leftPanel={
            <>
              <SelectPanel
                store={store}
                options={PREMIUM_OPTIONS}
                label="Tipo de conta"
                getLabel={(item) => item?.label ?? "Todos"}
                entity="type"
                hideNull={true}
              />
            </>
          }
          rightPanel={
            <>
              <SortPanel
                store={store}
                filters={{
                  name: "Nome",
                  email: "Email",
                  createdDate: "Data de Criação da Conta",
                }}
              />
              <SearchField store={store} />
            </>
          }
          onEdit={canEdit ? () => store.open() : null}
        />

        <Edit onSave={() => AuthenticationStore.testAuthenticated()} />
      </>
    );
  }
}

export default observer(UserPremium);

import React from "react";
import { observer } from "mobx-react";

import List from "../../components/List";

import AuthenticationStore from "../../stores/AuthenticationStore";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";

import Edit from "./Edit";
import SearchField from "../../components/SearchField";
import SortPanel from "../../components/SortPanel";
import dayjs from "dayjs";

const store = Stores.load("contents", "createdDate");

class Contents extends React.Component {
  componentDidMount() {
    if(AuthenticationStore.me?.role === "ADMIN"){
      store.authorId = null;
    } else{
      store.authorId = AuthenticationStore.me.id;
    }
    UIStore.init("Contents", store);
  }

  handleDelete = () => {
    let count = store.selected.length;

    UIStore.showConfirmDialog(
      `Deseja remover ${count} conteúdos?`,
      "Remover Conteúdos"
    ).then((b) => {
      if (b) {
        store
          .delete()
          .then(() => store.query())
          .catch(() => alert("Erro!"));
      }
    });
  };

  handleFilter = () => {
    store.page = 0;
    store.searchAll = !store.searchAll;
    store.query();
  };

  render() {
    let canEdit;
    if (AuthenticationStore.me.role === "ADMIN" || AuthenticationStore.me.role === "EXPERT") {
      canEdit = true;
    } else {
      canEdit = true;
    }

    return (
      <>
        <List
          store={store}
          data={store.list.map((i) => ({
            item: i,
            title: `${i.title} (${i.type})`,
            content: `Por ${i.author.name} em ${dayjs(i.createdDate).format(
              "DD/MM/YYYY hh:mm"
            )}`,
          }))}
          status={[]}
          rightPanel={
            <>
              <SortPanel
                store={store}
                filters={{
                  createdDate: "Data de Criação",
                }}
              />
              <SearchField store={store} />
            </>
          }
          onAdd={
            canEdit
              ? () =>
                  store.new({
                    title: "",
                    type: "TEXT",
                  })
              : null
          }
          onEdit={canEdit ? () => store.open() : null}
          onDelete={canEdit ? () => this.handleDelete() : null}
        />

        <Edit />
      </>
    );
  }
}

export default observer(Contents);

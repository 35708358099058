import { observer } from "mobx-react";
import React from "react";
import { Button, InputAdornment } from "@material-ui/core";
import {
  Person,
  Email,
  Label,
} from "@material-ui/icons";
import TextField from "../../components/TextField";
import EditDialog from "../../components/EditDialog";
import SelectField from "../../components/SelectField";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import { REQUIRED } from "../../utils/Validation";
import dayjs from "dayjs";


const store = Stores.load("user-premium");
const producerTypeOptions = [
  { label: "Cervejeiro", value: "BREWER" },
  { label: "Cervejaria", value: "BREWERY" },
];
const ENTITLEMENT_LABEL = {
  "homebrewer": "CASEIRO",
  "nano-brewery": "NANO CERVEJARIA",
  "micro-brewery": "MICRO CERVEJARIA",
}
const PREMIUM_OPTIONS = [
  { label: "Caseiro", value: "HOMEBREWER" },
  { label: "Nano Cervejaria", value: "NANO_BREWERY" },
  { label: "Micro Cervejaria", value: "MICRO_BREWERY" },
];
class UserPremiumEdit extends React.Component {
  state = {
    submitting: false,
    premiumPlanType: null,
  };

  componentDidMount() {
    store.rules = {
      name: REQUIRED,
      email: REQUIRED,
      role: REQUIRED,
    };
  }

  save = () =>
    store
      .save(this.props.onSave)
      .then(() => UIStore.redo(store))
      .catch(UIStore.saveError);


  grantPremium = () => {
    this.setState({ submitting: true }, async () => {
      try{
        await store.grantPremium();
      } catch(e){
        console.log(e);
      } finally{
        this.setState({ submitting: false });
      }
    })
  }

  render() {
    this.item = store.edit != null ? store.edit : {};
    let roles = [
      { label: "Administrador", value: "ADMIN" },
      { label: "Usuário", value: "USER" },
      { label: "Expert", value: "EXPERT" },
    ];

    return (
      <EditDialog
        title={this.item.id ? "Editar Usuário" : "Novo Usuário"}
        opened={store.edit != null}
        onCancel={() => (store.edit = null)}
        onSave={this.save}
      >
        <div className="rowStyles">
          <div className="columnStyles">
            <TextField
              disabled
              field="name"
              item={this.item}
              label="Nome"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              field="email"
              item={this.item}
              label="E-mail"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <SelectField
              disabled
              field="producerType"
              item={this.item}
              label="&nbsp; Tipo de conta"
              list={producerTypeOptions}
              style={styles.inputOutline}
              variant="outlined"
            />
            <SelectField
              disabled
              field="role"
              item={this.item}
              label="&nbsp; Tipo"
              list={roles}
              style={styles.inputOutline}
              variant="outlined"
            />
            <TextField
              disabled
              format={(value) => dayjs(value).format("DD/MM/YYYY HH:mm:ss")}
              field="createdDate"
              item={this.item}
              label="Data de criação da conta"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Label color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              field="revenueCatAppUserId"
              item={this.item}
              label="RevenueCat ID"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Label color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="columnStyles">
          <h3>Conceder assinatura premium</h3>
            <SelectField 
              field="premiumPlanType"
              item={store}
              label="&nbsp; Plano Premium"
              list={PREMIUM_OPTIONS}
              onChange={(e) => (store.premiumPlanType = e.target.value)}
              style={styles.inputOutline}
              variant="outlined"
            />
            <TextField
              field="expirationDate"
              item={store}
              label="Válido até"
              onChange={(e) => (store.expirationDate = e.target.value.formatDate())}
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Label color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <Button 
              onClick={this.grantPremium}
              disabled={this.state.submitting || !store.premiumPlanType || store.expirationDate.length < 10}
              style={{ fontWeight: "bold" }}
            >
              CONCEDER PREMIUM
            </Button>
            <h3>Assinaturas Ativas</h3>
            { !!this.item?.revenueCatSubscriber?.subscriber && Object.keys(this.item.revenueCatSubscriber.subscriber.entitlements).map(e =>
              dayjs().isBefore(dayjs(this.item.revenueCatSubscriber.subscriber.entitlements[e].expires_date)) &&
              <div 
                className="columnStyles"
                style={{
                  borderWidth: 1,
                  borderRadius: 10,
                  borderColor: "#CCCCCC",
                  padding: 6,
                  backgroundColor: "#FFFFFF",
                  marginBottom: 20,
                }}
              >
                <TextField
                  disabled
                  field="value"
                  format={(value) => 
                    `${ENTITLEMENT_LABEL[value]}${this.item.revenueCatSubscriber.subscriber.entitlements[e].product_identifier.startsWith("rc_promo") ? " - PROMOCIONAL" : ""}`
                  }
                  item={{ value: e }}
                  label="Nome da assinatura"
                  style={styles.inputOutline}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Label color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  disabled
                  format={(value) => dayjs(value).format("DD/MM/YYYY HH:mm:ss")}
                  field="purchase_date"
                  item={this.item.revenueCatSubscriber.subscriber.entitlements[e]}
                  label="Data da assinatura"
                  style={styles.inputOutline}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Label color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  disabled
                  format={(value) => dayjs(value).format("DD/MM/YYYY HH:mm:ss")}
                  field="expires_date"
                  item={this.item.revenueCatSubscriber.subscriber.entitlements[e]}
                  label="Válido até"
                  style={styles.inputOutline}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Label color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          </div>  
        </div>
      </EditDialog>
    );
  }
}

export default observer(UserPremiumEdit);

const styles = {
  inputOutline: {
    marginBottom: "20px",
  },
};

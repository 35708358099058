import { observer } from "mobx-react";
import React from "react";
import TextField from "../../components/TextField";
import EditDialog from "../../components/EditDialog";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";


const store = Stores.load("beerstyles");

class UserEdit extends React.Component {
  componentDidMount() {
    store.rules = {};
  }

  save = () =>
    store
      .save(this.props.onSave)
      .then(() => UIStore.redo(store))
      .catch(UIStore.saveError);

  render() {
    this.item = store.edit != null ? store.edit : {};

    return (
      <EditDialog
        title={this.item.id ? "Editar Estilo" : "Novo Estilo"}
        opened={store.edit != null}
        onCancel={() => (store.edit = null)}
        onSave={this.save}
      >
        <div className="rowStyles">
          <div className="columnStyles">
            <TextField
              errors={store.errors}
              field="name"
              item={this.item}
              label="Nome"
              style={styles.inputOutline}
              variant="outlined"
            />
            <TextField
              errors={store.errors}
              field="color"
              item={this.item}
              label="Color"
              style={styles.inputOutline}
              variant="outlined"
            />
            <TextField
              errors={store.errors}
              field="clarity"
              item={this.item}
              label="Clarity"
              style={styles.inputOutline}
              variant="outlined"
            />
            <TextField
              errors={store.errors}
              field="perceivedMaltAromaFlavour"
              item={this.item}
              label="Perceived Malt & Aroma Flavor"
              style={styles.inputOutline}
              variant="outlined"
            />
          </div>
          <div className="columnStyles">
            <TextField
              errors={store.errors}
              field="perceivedHopAromaFlavour"
              item={this.item}
              label="Perceived Hop & Aroma Flavor"
              style={styles.inputOutline}
              variant="outlined"
            />
            <TextField
              errors={store.errors}
              field="perceivedBitterness"
              item={this.item}
              label="Perceived Bitterness"
              style={styles.inputOutline}
              variant="outlined"
            />
            <TextField
              errors={store.errors}
              field="fermentationCharacteristics"
              item={this.item}
              label="Fermentation Characteristics"
              style={styles.inputOutline}
              variant="outlined"
            />
            <TextField
              errors={store.errors}
              field="body"
              item={this.item}
              label="Body"
              style={styles.inputOutline}
              variant="outlined"
            />
          </div>
        </div>
      </EditDialog>
    );
  }
}

export default observer(UserEdit);

const styles = {
  inputOutline: {
    marginBottom: "20px",
  },
};

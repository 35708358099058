import React from "react";
import { createRoot } from 'react-dom/client';
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { unregister } from "./serviceWorker";
import "./index.css";
import "./utils/Format";
import App from "./screens/App";
import Home from "./screens/Home";
import Login from "./screens/Login";
import Register from "./screens/Register";
import Users from "./screens/Users";
import Contents from "./screens/Contents"
import Banners from "./screens/Banners";
import ForumReports from "./screens/ForumReports";
import HelpRequests from "./screens/HelpRequests";
import AuthenticationStore from "./stores/AuthenticationStore";
import Reports from "./screens/Reports";
import { observer } from "mobx-react";
import AccountDeletionRequests from "./screens/AccountDeletionRequests";
import AccountDeletionRequestForm from "./screens/AccountDeletionRequestForm";
import ChallengeGroups from "./screens/ChallengeGroups";
import BeerStyles from "./screens/BeerStyles";
import ChallengeRankings from "./screens/ChallengeRankings";
import UserPremium from "./screens/UserPremium";
//import ExpertRoleRequests from "./screens/ExpertRoleRequests";
import dayjs from "dayjs";
import HopsCups from "./screens/HopsCups";
import HopsCupFarm from "./screens/HopsCupFarm";
require('dayjs/locale/pt-br')
dayjs.locale("pt-br");


export const history = createBrowserHistory();

const Routes = observer(() => {
  const { me } = AuthenticationStore;

  return(
    <Router history={history}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/account-deletion-form" component={AccountDeletionRequestForm} />
        <App>
          { (me?.role === "ADMIN") ?
            <>
              <Route exact path="/" component={Home} />
              <Route exact path="/users" component={Users} />
              <Route exact path="/contents" component={Contents} />
              <Route exact path="/banners" component={Banners} />
              <Route exact path="/forum-reports" component={ForumReports} />
              <Route exact path="/help-requests" component={HelpRequests} />
              <Route exact path="/reports" component={Reports} />
              <Route exact path="/account-deletion" component={AccountDeletionRequests} />
              <Route exact path="/challenge-groups" component={ChallengeGroups} />
              <Route exact path="/beer-styles" component={BeerStyles} />
              <Route exact path="/challenge-rankings" component={ChallengeRankings} />
              <Route exact path="/user-premium" component={UserPremium} />
              <Route exact path="/hops-cups" component={HopsCups} />
              <Route exact path="/hops-cup-farms" component={HopsCupFarm} />
              {
                //<Route exact path="/expert-role-requests" component={ExpertRoleRequests} />
              }
            </>
            : (me?.role === "EXPERT") &&
            <>
              <Route exact path="/" component={Home} />
              <Route exact path="/contents" component={Contents} />
              <Route exact path="/reports" component={Reports} />
            </>
          }
        </App>
      </Switch>
    </Router>
  )
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <div>
    <Routes />
  </div>
);
unregister();

import { observer } from "mobx-react";
import React from "react";
import { Avatar, InputAdornment } from "@material-ui/core";
import {
  Delete,
  Description,
  ExpandLess, 
  ExpandMore,
} from "@material-ui/icons";
import TextField from "../../components/TextField";
import { baseUrl } from "../../utils/Client";
import ImageUpload from "../../components/ImageUpload";


class OrganizerCard extends React.Component {
  state = {
    open: false
  }

  componentDidMount() {
    
  }

  render() {
    const { open } = this.state;
    const { 
      organizer, 
      errors, 
      onChange, 
      onDelete,
    } = this.props;
    
    return (
      <div style={styles.card}>
        <div style={styles.delete}>
          <div styles={{ flex: 1 }}/>
          <div 
            style={styles.deleteBtn}
            onClick={onDelete}
          >
            <Delete />
          </div>
        </div>
        { open ? (
            <>
              <div style={styles.inputOutline}>
                <div style={styles.imgLabel}>
                  {"Foto (1:1)"}
                </div>
                <ImageUpload
                  label="Foto (1:1)"
                  value={
                    !!organizer.avatar
                      ? organizer.avatar
                      : `${baseUrl}/img/hops-cup-organizer/${organizer.id}`
                  }
                  width={150}
                  height={150}
                  onChange={(photo) => onChange("avatar", photo)}
                />
              </div>
              <TextField
                errors={errors}
                field="name"
                item={organizer}
                label="Nome"
                placeholder="Nome do organizador"
                onChange={(e) => onChange("name", e.target.value)}
                style={styles.inputOutline}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Avatar color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                errors={errors}
                field="description"
                item={organizer}
                label="Descrição do organizador"
                placeholder="Descrição do organizador"
                rows={5}
                onChange={(e) => onChange("description", e.target.value.slice(0, 300))}
                style={styles.inputOutline}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Description color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
              <div style={styles.counter}>
                {`${organizer?.description?.length ?? 0}/300 caracteres`}
              </div>
              <div
                onClick={() => this.setState({ open: false })}
                style={styles.showMore}
              >
                <ExpandLess /> 
              </div>
            </>
        ) : (
          <>
            <img 
              src={ !!organizer.avatar 
                ? organizer.avatar
                : `${baseUrl}/img/hops-cup-organizer/${organizer.id}/100/100?count=${organizer.avatarCount}`
              }
              alt="organizer-avatar"
              style={{ borderRadius: 100, width: 100, height: 100, alignSelf: "center" }}
            />
            <div style={styles.cardValue}>
              {!!organizer.name ? organizer.name : "Sem nome"}
            </div>
            <div
              onClick={() => this.setState({ open: true })}
              style={styles.showMore}
            >
              <ExpandMore /> 
            </div>
          </>
          )
        }
      </div>
    );
  }
}

export default observer(OrganizerCard);

const styles = {
  card: {
    backgroundColor: "#F2F2F2",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    paddingTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    minWidth: "300px",
  },
  inputOutline: {
    marginBottom: "20px",
  },
  cardValue: {
    fontWeight: "700",
    fontSize: "14px",
  },
  showMore: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: "12px",
  },
  delete: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  deleteBtn: {
    cursor: "pointer",
    color: "#F00",
  },
  imgLabel: {
    fontSize: "12px",
    color: "#777",
  },
  counter: {
    fontSize: "10px",
    marginTop: "-10px",
  },
};

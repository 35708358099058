import React, { Component } from "react";
import PropTypes from "prop-types";

export default class ImageUpload extends Component {
  input = null;
  state = { showInstruction: false };

  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    label: PropTypes.string,
    onChange: PropTypes.func
  };

  static defaultProps = {
    width: 250,
    height: 250,
    label: "Trocar Imagem",
    onChange: image => {}
  };

  select = () => {
    let { onChange } = this.props;
    if (this.input.files.length > 0) {
      var file = this.input.files[0];

      var reader = new FileReader();
      reader.addEventListener("load", function(event) {
        var loadedFile = event.target;
        if (file.type.match("image")) {
          onChange(loadedFile.result);
        }
      });

      reader.readAsDataURL(file);
    }
  };

  render() {
    let { showInstruction } = this.state;
    let { width, height, label, value } = this.props;

    let previewStyle = {
      position: "relative",
      width: `${width}px`,
      height: `${height}px`,
      border: "1px solid grey"
    };
    if (value) {
      previewStyle.backgroundImage = `url(${value})`;
      previewStyle.backgroundSize = "cover";
      previewStyle.backgroundPosition = "center center";
    }

    return (
      <div
        style={previewStyle}
        onMouseOver={() => this.setState({ showInstruction: true })}
        onMouseOut={() => this.setState({ showInstruction: false })}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 0,
            opacity: showInstruction ? 0.5 : 0,
            backgroundColor: "#bdc3c7",
            width: `${width}px`,
            height: "50px",
            fontSize: "20px",
            lineHeight: "50px",
            textTransform: "uppercase",
            bottom: 0,
            margin: "auto",
            textAlign: "center",
            transition: "all 0.2s"
          }}
        >
          {label}
        </div>
        <input
          ref={ref => (this.input = ref)}
          style={{ width, height, opacity: 0, zIndex: 10, cursor: "pointer" }}
          type="file"
          onChange={this.select}
        />
      </div>
    );
  }
}

import React from "react";
import { observer } from "mobx-react";
import UIStore from "../../stores/UIStore";
import { jsonParser, multipartForm, post } from "../../utils/Client";
import { Button, Snackbar, TextField } from "@material-ui/core";
import AuthenticationStore from "../../stores/AuthenticationStore";


class Home extends React.Component {
  state = {
    notification: {
      title: "",
      description: "",
    },
    submitting: false,
    error: "",
  }

  componentDidMount() {
    UIStore.init("Principal", null, null, false);
  }

  submitNotification = async () => {
    try{
      await post(`/notifications/push/all`, { body: this.state.notification }).then(jsonParser);
    } catch(e){
      console.log(e);
      this.setState({ error: !!e.message ? e.message : e });
    } finally{
      this.setState({ submitting: false, notification: { title: "", description: "" }, error: "" });
    }
  }

  submitUserImport = (e) => {
    if(!e?.target?.files || e.target.files.length === 0){
      return;
    }

    this.setState({ submitting: true, error: "" }, async () => {
      try{
        const { name, size, type } = e.target.files[0];
        if(type !== "text/csv"){
          throw new Error("O arquivo deve estar no formato csv");
        }
        if(size > 10000000){
          throw new Error("O arquivo não pode ser maior que 10MB");
        }

        const formData = new FormData();
        formData.append("file", e.target.files[0], name);
        await multipartForm(`/users/import`, { body: formData }).then(jsonParser);
      } catch(e){
        console.log(e);
        this.setState({ error: !!e.message ? e.message : e });
      } finally{
        this.setState({ submitting: false });
      }
    })
  }

  render() {
    const { submitting, notification, error } = this.state;

    return (
      <div className="holder">
        <h3>Bem vindo!</h3>
        { (AuthenticationStore.me?.role === "ADMIN") &&
          <>
            <div 
              style={{ 
                marginTop: 30, 
                marginLeft: 60, 
                marginRight: 60, 
                borderRadius: 5, 
                backgroundColor: "#efefef" 
              }}
            >
              <div>Enviar notificações para todos os usuários</div>
              <div style={{ display: "flex", flexDirection: "column", padding: 20 }}>
                <TextField
                  label="Título"
                  multiline
                  onChange={(e) => {
                    const { target } = e;
                    if(!!target){
                      this.setState(v => ({ notification: { ...v.notification, ...{ title: target.value } } }))
                    }
                  }}
                  rows={5}
                  style={{ marginBottom: "5px" }}
                  value={notification.title}
                />
                <TextField
                  label="Mensagem"
                  multiline
                  onChange={(e) => {
                    const { target } = e;
                    if(!!target){
                      this.setState(v => ({ notification: { ...v.notification, ...{ description: target.value } } }))
                    }
                  }}
                  rows={5}
                  style={{ marginBottom: "5px" }}
                  value={notification.description}
                />
                <div style={{ marginTop: 20 }}>
                  <Button 
                    variant="contained" 
                    disabled={submitting || !notification.title || !notification.description}
                    onClick={() => this.setState({ submitting: true }, this.submitNotification)}
                  >
                    Enviar Notificação
                  </Button>
                </div>
              </div>
            </div>

            <div 
              style={{ 
                marginTop: 30, 
                marginLeft: 60, 
                marginRight: 60, 
                borderRadius: 5, 
                backgroundColor: "#efefef" 
              }}
            >
              <div>Importar usuários (CSV)</div>
              <div 
                style={{
                  fontSize: 16,
                }}
              >
                O arquivo CSV deve conter duas colunas: nome e email. A primeira linha é ignorada.
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                style={{ display: "flex", flexDirection: "column", padding: 20 }}
              >
                <input 
                  type="file"
                  accept=".csv"
                  multiple={false}
                  id="contained-btn-file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    e.persist();
                    this.submitUserImport(e);
                  }}
                />
                <label htmlFor="contained-btn-file">
                  <Button 
                    variant="contained" 
                    component="span"  
                  >
                    Upload
                  </Button>
                </label>
              </form>
            </div>
          </>
        }
        <Snackbar
          autoHideDuration={7000}
          open={!!error}
          message={error}
          onClose={() => this.setState({ error: "" })}
        />
      </div>
    );
  }
}

export default observer(Home);

import { observer } from "mobx-react";
import React from "react";
import { Button, Tab, Tabs } from "@material-ui/core";
import {
  AddCircle,
} from "@material-ui/icons";
import TextField from "../../components/TextField";
import EditDialog from "../../components/EditDialog";
import CheckBox from "../../components/CheckBox";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import { REQUIRED } from "../../utils/Validation";
import ImageUpload from "../../components/ImageUpload";
import { baseUrl, get, jsonParser } from "../../utils/Client";
import SearchField from "../../components/SearchField";
import SelectField from "../../components/SelectField";
import HopCard from "./HopCard";
import { UFs } from "../../utils/Constants";
import Visualizations from "./Visualizations";
import { PhoneNumberUtil } from "google-libphonenumber";
const getCountryISO2 = require("country-iso-3-to-2");


const OIL_TYPES = [
  "MYRCENE",
  "CARYOPHYLLENE",
  "HUMULENE",
  "FARNESENE",
  "OTHERS",
];

const store = Stores.load("hops-cup-farms");
const hopStore = Stores.load("hops", "title");
hopStore.page = 0;
hopStore.size = 999999;

class Edit extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      selectedTab: 0,
      hopsStats: [],
      stats: {
        visualizations: 0,
        phoneClicks: 0,
      },
      countries: [],
    };
  }

  loadCountries = async() => {
    try{
      const res = await get(`/countries?page=0&size=9999&sort=name&order=ASC`).then(jsonParser);
      const filtered = res.content.filter(c => !!getCountryISO2(c.isoCode));
      this.setState({ 
        countries: filtered.map(c => {
          const iso2 = getCountryISO2(c.isoCode);
          return { label: `${c.name}`, value: iso2 };
        }),
      })
    } catch(e){
      console.log(e);
    }
  }

  componentDidMount() {
    this.loadCountries();
    store.rules = {
      name: REQUIRED,
      about: REQUIRED,
      history: REQUIRED,
      hops: REQUIRED,
      uf: REQUIRED,
      city: REQUIRED,
    };
  }

  save = async () => {
    try{
      this.item.whatsapp = this.item.whatsapp.replace(new RegExp("[^0-9]", "g"), "");
      const phoneUtil = PhoneNumberUtil.getInstance();
      const phoneNumber = phoneUtil.parseAndKeepRawInput(this.item.whatsapp, this.item.phoneCountry);
      const isValid = phoneUtil.isValidNumber(phoneNumber);
      if(!isValid){
        store.errors['whatsapp'] = "O número de telefone é inválido";
        return;
      }
    } catch(e){
      console.log(e);
      store.errors['whatsapp'] = "O número de telefone é inválido";
      return;
    }
    try{
      store.resetErrors();
      await store.save(this.props.onSave);
      UIStore.redo(store);
    }catch(e){
      console.log(e.message ?? e);
      UIStore.saveError(e);
    }
  }

  getHopStats(id){
    get(`/hops-cup-farms/stats/hops/${id}`)
      .then(jsonParser)
      .then((res) => this.setState({ hopsStats: res.sort((h1, h2) => h2.visualizations - h1.visualizations ) }))
      .catch((e) => {
        console.log(!!e.message ? e.message : e);
        this.setState({ hopsStats: [] });
      })
  }

  getFarmStats(id){
    get(`/hops-cup-farms/stats/farm/${id}`)
      .then(jsonParser)
      .then((res) => this.setState({ stats: res }))
      .catch((e) => {
        console.log(!!e.message ? e.message : e);
        this.setState({ stats: { visualizations: 0, phoneClicks: 0 } });
      })
  }

  render() {
    const { selectedTab, hopsStats, stats, countries } = this.state;
    this.item = store.edit != null ? store.edit : {};
    
    return (
      <EditDialog
        title={this.item.id ? "Editar Fazenda" : "Nova Fazenda"}
        opened={store.edit != null}
        onCancel={() => {
          this.setState({ selectedTab: 0, hopsStats: [] });
          store.edit = null;
        }}
        onSave={this.save}
      >
        <div>
          { !!this.item.id &&
            <div className="rowStyles" style={{ marginTop: -30 }}>
              <Tabs
                value={selectedTab} 
                onChange={(event, value) => {
                  this.setState({ selectedTab: value });
                  if(value === 1){
                    this.getHopStats(this.item.id);
                    this.getFarmStats(this.item.id);
                  }
                }} 
                aria-label="hop farm tabs"
              >
                <Tab label="Geral" value={0} />
                <Tab label="Visualizações" value={1} />
              </Tabs>
            </div>
          }
          { selectedTab === 0 ?
            <div className="rowStyles">
              <div className="columnStyles">
                <SelectField
                  field="uf"
                  item={this.item}
                  label="Estado"
                  list={UFs}
                  style={styles.inputOutline}
                  variant="outlined"
                />
                <TextField
                  errors={store.errors}
                  field="city"
                  item={this.item}
                  label="Cidade"
                  placeholder="Cidade"
                  variant="outlined"
                  style={styles.inputOutline}
                />
                <TextField
                  errors={store.errors}
                  field="name"
                  item={this.item}
                  label="Nome"
                  placeholder="Nome"
                  variant="outlined"
                  style={styles.inputOutline}
                />
                <SelectField
                  errors={store.errors}
                  field="phoneCountry"
                  item={this.item}
                  label="&nbsp; País origem do telefone"
                  list={countries}
                  style={styles.inputOutline}
                  variant="outlined"
                  onChange={(e) => {
                    this.item.phoneCountry = e.target.value;
                  }}
                />
                { !!this.item.phoneCountry &&
                  <TextField
                    errors={store.errors}
                    field="whatsapp"
                    item={this.item}
                    label="Whatsapp"
                    placeholder="Whatsapp"
                    variant="outlined"
                    style={styles.inputOutline}
                    onChange={e => {
                      this.item.whatsapp = e.target.value.replace(new RegExp("[^0-9]", "g"), "");
                      try{
                        const phoneUtil = PhoneNumberUtil.getInstance();
                        const phoneNumber = phoneUtil.parseAndKeepRawInput(this.item.whatsapp, this.item.phoneCountry);
                        this.item.whatsapp = phoneUtil.formatOutOfCountryCallingNumber(phoneNumber, this.item.phoneCountry);
                        const isValid = phoneUtil.isValidNumber(phoneNumber);
                        if(isValid){
                          delete store.errors['whatsapp'];
                        } else{
                          store.errors['whatsapp'] = "O número de telefone é inválido";
                        }
                      } catch(e){
                        console.log(e);
                        store.errors['whatsapp'] = "O número de telefone é inválido";
                      }
                    }}
                  />
                }
                <TextField
                  errors={store.errors}
                  field="about"
                  item={this.item}
                  label="Sobre"
                  placeholder="Sobre"
                  variant="outlined"
                  rows={5}
                  onChange={(e) => {
                    this.item.about = e.target.value.slice(0, 2000);
                  }}
                />
                <div style={styles.counter}>
                  {`${this.item?.about?.length ?? 0}/2000 caracteres`}
                </div>
                <TextField
                  errors={store.errors}
                  field="history"
                  item={this.item}
                  label="História"
                  placeholder="História"
                  variant="outlined"
                  rows={5}
                  onChange={(e) => {
                    this.item.history = e.target.value.slice(0, 2000);
                  }}
                />
                <div style={styles.counter}>
                  {`${this.item?.history?.length ?? 0}/2000 caracteres`}
                </div>
                <div style={styles.inputOutline}>
                  <div style={styles.imgLabel}>
                    {"Ícone (1000x1000 pixels com fundo transparente)"}
                  </div>
                  <ImageUpload
                    label="Ícone"
                    value={!!this.item.image
                        ? this.item.image
                        : `${baseUrl}/img/hops-cup-farm/${this.item.id}/550/550?count=${this.item.imageCount}`
                    }
                    width={275}
                    height={275}
                    onChange={(photo) => (this.item.image = photo)}
                  />
                </div>
                <div style={styles.inputOutline}>
                  <div style={styles.imgLabel}>
                    {"Imagem Card (1000x500 pixels)"}
                  </div>
                  <ImageUpload
                    label="Imagem Card"
                    value={!!this.item.cardImage
                        ? this.item.cardImage
                        : `${baseUrl}/img/hops-cup-farm-card/${this.item.id}/550/275?count=${this.item.cardImageCount}`
                    }
                    width={275}
                    height={137}
                    onChange={(photo) => (this.item.cardImage = photo)}
                  />
                </div>
                <div style={styles.inputOutline}>
                  <div style={styles.imgLabel}>
                    {"Imagem História (1000x500 pixels)"}
                  </div>
                  <ImageUpload
                    label="Imagem História"
                    value={!!this.item.historyImage
                        ? this.item.historyImage
                        : `${baseUrl}/img/hops-cup-farm-history/${this.item.id}/550/275?count=${this.item.historyImageCount}`
                    }
                    width={275}
                    height={137}
                    onChange={(photo) => (this.item.historyImage = photo)}
                  />
                </div>
                <TextField
                  errors={store.errors}
                  field="orderIndex"
                  item={this.item}
                  label="Posição"
                  placeholder="Posição"
                  variant="outlined"
                  onChange={(e) => {
                    this.item.orderIndex = e.target.value.replace(new RegExp("[^0-9]", "g"), "");;
                  }}
                />
                <div>
                  <CheckBox label="Premiado?" field="awarded" item={this.item} />
                </div>
                { !!this.item.id &&
                  <div>
                    <CheckBox label="Liberado?" field="enabled" item={this.item} />
                  </div>
                }
              </div>
              <div className="columnStyles" style={{ marginLeft: 20, marginRight: 20 }}>
                <SearchField store={hopStore} />
                <div style={styles.row}>
                  <SelectField
                    field="selectedHop"
                    item={this.item}
                    label="Lúpulo"
                    list={hopStore.list.map(h => ({ label: h.title, value: h.id }))}
                    onChange={(e) => (this.item.selectedHop = e.target.value)}
                    variant="outlined"
                  />
                  <Button
                    disabled={!this.item?.selectedHop}
                    onClick={() => {
                      if(!this.item.hops){
                        this.item.hops = [];
                      }
                      this.item.hops.push({ 
                        hop: hopStore.list.find(h => h.id === this.item.selectedHop),
                        enabled: true, 
                        oils: OIL_TYPES.map((o) => ({
                          type: o,
                          totalOils: "",
                          minOils: "",
                          maxOils: "",
                          //flavours: "",
                        }))
                      });
                      this.item.selectedHop = null;
                      hopStore.queryField = "";
                    }}
                    style={styles.btn}
                  >
                    <div style={styles.row}>
                      <AddCircle color="primary" fontSize="large" />
                      <div style={{ marginLeft: "10px" }}>Adicionar</div>
                    </div>
                  </Button>
                </div>
                <div style={styles.cardsRow}>
                  { this.item?.hops?.map((hop, index) => hop.enabled &&
                    <div key={`hop-${index}`}>
                      <HopCard
                        awardType={hop.awardType}
                        hop={hop.hop}
                        onChange={(name, value) => {
                          hop.hop[name] = value;
                        }}
                        onChangeType={(value) => {
                          hop.awardType = value;
                        }}
                        onChangeOil={(index, name, value) => {
                          hop.hop.oils[index][name] = value;
                        }}
                        onDelete={() => {
                          if(!!hop.id){
                            hop.enabled = false;
                          } else{
                            this.item.hops = this.item.hops.filter(p => p !== hop);
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            : selectedTab === 1 &&
            <Visualizations 
              stats={stats}
              hopsStats={hopsStats}
            />
          }
        </div>
      </EditDialog>
    );
  }
}

export default observer(Edit);

const styles = {
  inputOutline: {
    marginBottom: "20px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  btn: {
    fontSize: "12px",
    fontWeight: "700",
    color: "#ff9900",
  },
  cardsRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    marginTop: "20px",
  },
  imgLabel: {
    fontSize: "12px",
    color: "#777",
  },
  counter: {
    marginBottom: 10,
  },
};

import { observer } from "mobx-react";
import React from "react";
import { InputBase } from "@material-ui/core";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import List from "../../components/List";
import SelectPanel from "../../components/SelectPanel";
import dayjs from "dayjs";
import DateRange from "../../components/DateRange";
import { Bar, BarChart, LabelList, Tooltip, XAxis, YAxis } from "recharts";
import { baseUrl } from "../../utils/Client";


const CATEGORIES = [
  { value: "TOP_FERMENTABLES", label: "Fermentáveis mais usados", isList: true },
  { value: "TOP_HOPS", label: "Lúpulos mais usados", isList: true },
  { value: "TOP_YEASTS", label: "Leveduras mais usados", isList: true },
  { value: "TOP_VOLUMES", label: "Litragens mais usadas em receitas", isGraph: true, hasStep: true, isLine: false },
  { value: "TOP_STYLES", label: "Estilos mais usados em receitas", isList: true },
];
const DATES = [
  { 
    label: "7 dias", 
    initialDate: dayjs().add(-7, "days").format("YYYY-MM-DD"), 
    finalDate: dayjs().format("YYYY-MM-DD") 
  },
  { 
    label: "15 dias", 
    initialDate: dayjs().add(-15, "days").format("YYYY-MM-DD"), 
    finalDate: dayjs().format("YYYY-MM-DD") 
  },
  { 
    label: "30 dias", 
    initialDate: dayjs().add(-30, "days").format("YYYY-MM-DD"),
    finalDate: dayjs().format("YYYY-MM-DD")
  },
  { 
    label: "Todos", 
    initialDate: null,
    finalDate: null
  },
];
const reportsStore = Stores.load("reports");

class RecipeStats extends React.Component {
  timer = null;

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
    UIStore.init("Reports", reportsStore);
  }
  
  componentDidUpdate(prevProps){
    const { user } = this.props;
    if(!!user && !!user.id && reportsStore.selectedUser !== user){
      this.resetReportsStore(this.props.user);
      if(!!this.props.user){
        reportsStore.query();
      }
    }
  }

  resetReportsStore = (user) => {
    reportsStore.selectedUser = user;
    reportsStore.initialDate = DATES[0].initialDate;
    reportsStore.category = CATEGORIES[0];
    reportsStore.size = 10;
    reportsStore.chartWidth = window.innerWidth * 0.75;
    reportsStore.chartHeight = window.innerWidth * 0.50;
    reportsStore.step = 100;
    reportsStore.list = [];
  }

  onResize = () => {
    reportsStore.chartWidth = window.innerWidth * 0.75;
    reportsStore.chartHeight = (reportsStore.count * 18) + 100;
  }

  renderRow = (item) => {
    var selected = {};
    var title = "";
    var valueLabel = "";
    var imgUrl = null;
    
    switch(reportsStore.category.value){
      case "TOP_FERMENTABLES":
        selected = item.fermentable;
        title = selected?.title;
        valueLabel = `Usado em ${item.value} receita${item.value > 1 ? "s" : ""}`;
        break;
      case "TOP_HOPS":
        selected = item.hop;
        title = selected?.title;
        valueLabel = `Usado em ${item.value} receita${item.value > 1 ? "s" : ""}`;
        imgUrl = `${baseUrl}/img/hop/${selected?.id}/200/200`;
        break;
      case "TOP_YEASTS":
        selected = item.yeast;
        title = selected?.title;
        valueLabel = `Usado em ${item.value} receita${item.value > 1 ? "s" : ""}`;
        break;
      case "TOP_STYLES":
        selected = item.beerStyle;
        title = selected?.name;
        valueLabel = `Usado em ${item.value} receita${item.value > 1 ? "s" : ""}`;
        break;
      default:
        return <></>;
    }

    return(
      {
        item: item,
        title: "",
        content: (
          <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <div>
              { !!imgUrl && <img src={imgUrl} style={{ width: 100, height: 100 }} alt="avatar" /> }
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 10, justifyContent: "center" }}>
              <div style={{ fontWeight: "bold", fontSize: 16, color: "#000" }}>{title}</div>
              <div>
                { !!selected?.author && `Criado por ${selected.author.name} (${selected.author.email})` }
              </div>
              <div>
                { valueLabel }
              </div>
            </div>
          </div>
        ),
      }
    )
  }

  render() {
    const { chartWidth, chartHeight } = reportsStore;

    return (
      <div style={{ width: "100%" }}>
        <List
          data={reportsStore.category.isList ? reportsStore.list.map((i) => this.renderRow(i)) : []}
          status={[]}
          leftPanel={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <SelectPanel
                store={reportsStore}
                options={CATEGORIES}
                label="Categoria"
                getLabel={(item) => item.label}
                entity="category"
                hideNull={true}
              />
            </div>
          }
          rightPanel={
            <div style={styles.col}>
              <div>
                <DateRange
                  store={reportsStore}
                  customDates={DATES}
                  initialLabel={DATES[0].label}
                  label="Período" 
                />
              </div>
              { reportsStore.category.hasStep &&
                <div style={styles.row}>
                  <div>Tamanho do passo:</div>
                  <InputBase
                    placeholder="Tamanho do passo"
                    onChange={(e) => {
                      reportsStore.step = e.target.value.replace(new RegExp("[^0-9]", "g"), "");
                      clearTimeout(this.timer);
                      this.timer = setTimeout(() => reportsStore.query(), 700);
                    }}
                    value={reportsStore.step}
                    disabled={reportsStore.loading}
                  />
                </div>
              }
              { reportsStore.category.showTotal &&
                <div style={styles.row}>
                  <div>{`Total: ${reportsStore.total}`}</div>
                </div>
              }
            </div>
          }
        />
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: -80 }}>
          { (reportsStore.category.isGraph && reportsStore.list.length > 0) &&
            <BarChart
              layout="vertical"
              width={chartWidth}
              height={chartHeight}
              data={reportsStore.list}
              margin={{ right: 0, left: 0, top: 10, bottom: 80 }}
            >
              <XAxis type="number" />
              <YAxis 
                type="category" 
                dataKey="name" 
                cursor="pointer"
                interval={0}
                tick={{ fontSize: 14 }}
                width={200}
                tickFormatter={(v, index) => v.length > 20 ? v.slice(0, 20) + "..." : v}
              />
              <Tooltip itemStyle={{ color: "black" }} labelStyle={{ color: "black" }}/>
              <Bar
                dataKey="value" 
                fill="#8884d8"
                cursor="pointer"
              >
                <LabelList
                  dataKey="value" 
                  position="right" 
                  style={{ fontSize: 14 }}
                />
              </Bar>
              <Tooltip />
            </BarChart>
          }
        </div>
      </div>
    );
  }
}

export default observer(RecipeStats);

const styles = {
  inputOutline: {
    marginBottom: "20px",
  },
};

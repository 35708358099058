import { Button, CssBaseline, InputAdornment, MuiThemeProvider, Snackbar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import theme from "../components/Theme";
import MainBar from "./MainBar";
import TextField from "../components/TextField";
import { Description, Email, Person } from "@material-ui/icons";
import { IS_EMAIL, REQUIRED, hasErrors, validate } from "../utils/Validation";
import { post } from "../utils/Client";
import { observer } from "mobx-react";


const AccountDeletionRequestForm = () => {
  const [form, setForm] = useState({
    email: "",
    name: "",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    document.title = "Beerbo"
  })

  const jsonParser = async (res) => {
    if (res.status === 200 || res.status === 201) {
      return res.text().then((t) => {
        try {
          return JSON.parse(t);
        } catch (e) {
          return null;
        }
      });
    } else {
      if(res.text){
        throw await res.text();
      } else{
        throw res;
      }
    }
  };

  const submit = async () => {
    setSubmitting(true);
    const rules = {
      name: REQUIRED,
      email: [REQUIRED, IS_EMAIL],
    };

    const errors = validate(rules, form);
    if(!hasErrors(errors)){
      try{
        await post("/account-deletion", { body: form }).then(jsonParser);
        setForm({
          email: "",
          name: "",
          description: "",
        });
        setMessage("Seu pedido foi enviado! Nossa equipe entrará em contato brevemente.");
      } catch(e){
        console.log(e.message ?? e);
        setMessage(e.message ?? e);
      }
    }
    setErrors(errors);
    setSubmitting(false);
  }

  return(
    <CssBaseline>
      <MuiThemeProvider theme={theme}>
        <MainBar 
          hideAsideBar
          hideToggle
          hideWelcome
          hideUserMenu
        />
        <div style={styles.body}>
          <h1 style={styles.title}>Pedido de Cancelamento de Conta</h1>
          <h4 style={styles.description}>
            Aqui você pode fazer o pedido para cancelamento de sua conta Beerbo. Após pedir o cancelamento, nossa equipe entrará em contato para verificar a sua identidade.
          </h4>

          <div style={styles.form}>
            <TextField
              errors={errors}
              field="email"
              item={form}
              label="E-mail"
              style={styles.inputOutline}
              variant="outlined"
              onChange={(e) => {
                e.persist();
                setForm(f => ({ ...f, ...{ email: e.target.value } }));
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email color="primary" />
                  </InputAdornment>
                ),
              }}
              disabled={submitting}
            />
            <TextField
              errors={errors}
              field="name"
              item={form}
              label="Nome"
              style={styles.inputOutline}
              variant="outlined"
              onChange={(e) => {
                e.persist();
                setForm(f => ({ ...f, ...{ name: e.target.value } }));
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person color="primary" />
                  </InputAdornment>
                ),
              }}
              disabled={submitting}
            />
            <TextField
              errors={errors}
              field="description"
              item={form}
              label="Motivo (opcional)"
              style={styles.inputOutline}
              variant="outlined"
              onChange={(e) => {
                e.persist();
                setForm(f => ({ ...f, ...{ description: e.target.value } }));
              }}
              rows={5}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Description color="primary" />
                  </InputAdornment>
                ),
              }}
              disabled={submitting}
            />

            <Button
              onClick={submit}
              style={styles.btn}
              disabled={submitting}
            >
              Enviar
            </Button>
          </div>

          <Snackbar
            autoHideDuration={10000}
            open={!!message}
            message={message}
            onClose={() => setMessage("")}
          />
        </div>
      </MuiThemeProvider>
    </CssBaseline>
  )

}


const styles = {
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 80,
    marginBottom: 80,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#000",
  },
  description: {
    fontSize: 16,
    fontWeight: "500",
    color: "#000",
    textAlign: "center",
    maxWidth: 400,
  },
  form: {
    maxWidth: 400,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  inputOutline: {
    marginBottom: "20px",
  },
  btn: {
    backgroundColor: "#FF9900",
  },
}

export default observer(AccountDeletionRequestForm);

import dayjs from "dayjs";

const REQUIRED = (field, item) =>
  !item[field] || item[field] === "" || item[field] === 0
    ? "O valor é requerido"
    : null;

const AFTER_TODAY = (field, item) =>
  REQUIRED(field, item) || dayjs().diff(item[field], "days") > 0
    ? "A data selecionada deve estar no futuro"
    : null;

const LESS_THAN = (otherField, label) => (field, item) =>
  item[field] >= item[otherField]
    ? `O valor deve ser menor que ${label}`
    : null;

const MORE_THAN = (otherField, label) => (field, item) =>
  item[field] <= item[otherField]
    ? `O valor deve ser maior que ${label}`
    : null;

const MORE_THAN_ZERO = (field, item) =>
  item[field] <= 0 ? "O valor deve ser maior que zero" : null;

const AT_LEAST_ONE = (field, item) =>
  !item[field] || !Array.isArray(item[field]) || item[field].length === 0
    ? "É necessário adicionar ao menos um valor."
    : null;

const urlPattern = new RegExp(
  "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + //port
    "(\\?[;&amp;a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
  "i"
);
const VALIDATE_URL = (field, item) => 
  !item[field] || item[field].length === 0 || !urlPattern.test(item[field])
  ? "URL inválida."
  : null;

const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const IS_EMAIL = (field, item) =>
  !item[field] || item[field].length === 0 || !emailPattern.test(item[field])
  ? "Email inválido."
  : null;

const validate = (rules, item) => {
  const errors = {};

  Object.keys(rules).forEach((k) => {
    const updateError = (rule) => {
      let msg = rule(k, item);
      if (msg) {
        errors[k] = msg;
      }
    };
    if (Array.isArray(rules[k])) {
      rules[k].forEach(updateError);
    } else {
      updateError(rules[k]);
    }
  });

  return errors;
}

const hasErrors = (errors) => {
  return Object.keys(errors).length > 0;
}

export {
  REQUIRED,
  AFTER_TODAY,
  LESS_THAN,
  MORE_THAN,
  MORE_THAN_ZERO,
  AT_LEAST_ONE,
  VALIDATE_URL,
  IS_EMAIL,
  validate,
  hasErrors,
};

import { extendObservable, action } from "mobx";

import { get, jsonParser } from "../utils/Client";

import { SIZE } from "../utils/Constants";

export default class ChallengeRankingStore {
  constructor() {
    const url = "/challenge-groups";

    extendObservable(this, {
      queryField: "",

      sort: { column: null, ascending: true },

      size: SIZE,

      page: 0,

      count: 0,

      list: [],

      selected: [],

      edit: null,

      rules: {},

      errors: {},

      challengeGroup: null,

      producerFilter: null,

      open: action(() =>
        get(url + `/user/${this.selected[0].user.id}?${!!this.challengeGroup ? "challengeGroupId=" + this.challengeGroup.value : ""}`)
          .then(jsonParser)
          .then((data) => (this.edit = data))
      ),

      query: action(() =>
        get(
            `${url}/ranking` +
            `?challengeGroupId=${!!this.challengeGroup ? this.challengeGroup.value : ""}` +
            `${(!!this.producerFilter && !! this.producerFilter.value) ? "&producerType=" + this.producerFilter.value : ""}`
        )
          .then(jsonParser)
          .then((data) => {
            this.list = data
            this.count = data.lenght;
          })
      ),
    });
  }
}

import { extendObservable } from "mobx";

import DefaultStore from "./DefaultStore";
import ChallengeRankingStore from "./ChallengeRankingStore";
import ChallengeGroupStore from "./ChallengeGroupStore";
import UserPremiumStore from "./UserPremiumStore";
import HopsCupStore from "./HopsCupStore";

class Stores {
  stores = {};

  extendsCustom = (store, entity) => {
    try {
      let custom = require("./" +
        entity.charAt(0).toUpperCase() +
        entity.slice(1) +
        "Store");
      extendObservable(store, custom.create(store));
    } catch (e) {}
  };

  load = (entity, sortColumn = "name") => {
    if (!this.stores[entity]) {
      if (entity === "challenge-groups"){
        this.stores[entity] = new ChallengeGroupStore(entity, sortColumn);
      } else if(entity === "challenge-rankings"){
        this.stores[entity] = new ChallengeRankingStore();
      } else if(entity === "user-premium"){
        this.stores[entity] = new UserPremiumStore(sortColumn);
      } else if(entity === "hops-cups"){
        this.stores[entity] = new HopsCupStore(entity, sortColumn);
      } else {
        this.stores[entity] = new DefaultStore(entity, sortColumn);
      }
      this.extendsCustom(this.stores[entity], entity);
    }
    return this.stores[entity];
  };

  create = (entity, sortColumn = "name") => {
    let store = new DefaultStore(entity, sortColumn);
    this.extendsCustom(store, entity);
    return store;
  };
}

export default new Stores();

import { observer } from "mobx-react";
import React from "react";
import { InputAdornment } from "@material-ui/core";
import { CallReceived, Link, Visibility } from "@material-ui/icons";

import TextField from "../../components/TextField";
import EditDialog from "../../components/EditDialog";
import CheckBox from "../../components/CheckBox";

import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import ImageUpload from "../../components/ImageUpload";
import { baseUrl } from "../../utils/Client";
import SelectField from "../../components/SelectField";

const store = Stores.load("banners", "createdDate");
const screens = [
  { label: "Tela Inicial", value: "HOME" },
  { label: "Fermentáveis", value: "FERMENTABLES" },
  { label: "Lúpulos", value: "HOPS" },
  { label: "Leveduras", value: "YEASTS" },
  { label: "Diversos", value: "ADDITIVES" },
  { label: "Equipamentos", value: "EQUIPMENTS" },
  { label: "Perfil de Fermentação", value: "FERMENTATION_PROFILE" },
  { label: "Perfil de Maturação", value: "MATURATION_PROFILE" },
  { label: "Perfil de Mostura", value: "MASHING_PROFILE" },
  { label: "Copa de Lúpulos - Tela Inicial", value: "HOPS_CUP_HOME" },
];


class BannerEdit extends React.Component {
  save = () => {
    const { url } = this.item;
    if(!!url && !url.startsWith("https://") && !url.startsWith("http://")){
      this.item.url = "https://" + url;
    }
    store
      .save(this.props.onSave)
      .then(() => UIStore.redo(store))
      .catch(UIStore.saveError);
  }

  render() {
    this.item = store.edit != null ? store.edit : {};

    return (
      <EditDialog
        title={this.item.id ? "Editar Banner" : "Novo Banner"}
        opened={store.edit != null}
        onCancel={() => (store.edit = null)}
        onSave={this.save}
      >
        <div className="rowStyles">
          <div className="columnStyles">
            <TextField
              errors={store.errors}
              field="url"
              item={this.item}
              label="URL"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Link color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <SelectField
              errors={store.errors}
              field="screenName"
              item={this.item}
              label="&nbsp; Tela"
              list={screens}
              style={styles.inputOutline}
              variant="outlined"
            />
            <TextField
              disabled
              field="visualizations"
              item={this.item}
              label="Visualizações"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Visibility color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              field="clicks"
              item={this.item}
              label="Cliques"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CallReceived color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <div style={{ fontSize: 14, fontWeight: "bold" }}>
              Selecione uma imagem com resolução de 328x180 (Sugestão: 984x540)
            </div>
            <ImageUpload
              label="Imagem (328:180)"
              value={
                this.item.image
                  ? this.item.image
                  : `${baseUrl}/img/banner/${this.item.id}?count=${this.item.imageCount}`
              }
              width={328}
              height={180}
              onChange={(photo) => (this.item.image = photo)}
            />
            <div style={{ marginTop: 20 }}>
              <TextField
                errors={store.errors}
                style={styles.inputOutline}
                field="orderIndex"
                item={this.item}
                label="Posição"
                placeholder="Posição"
                variant="outlined"
                onChange={(e) => {
                  this.item.orderIndex = e.target.value.replace(new RegExp("[^0-9]", "g"), "");;
                }}
              />
            </div>
            { !!this.item.id &&
              <div>
                <CheckBox label="Liberado?" field="enabled" item={this.item} />
              </div>
            }
          </div>
        </div>
      </EditDialog>
    );
  }
}

export default observer(BannerEdit);

const styles = {
  inputOutline: {
    marginBottom: "20px",
  },
};

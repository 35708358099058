import { observer } from "mobx-react";
import React from "react";
import { InputAdornment } from "@material-ui/core";
import {
  Person,
  Email,
  Label,
  EmojiEvents,
  Phone,
} from "@material-ui/icons";
import TextField from "../../components/TextField";
import EditDialog from "../../components/EditDialog";
import SelectField from "../../components/SelectField";
import Stores from "../../stores/Stores";


const store = Stores.load("challenge-rankings");
const producerType = [
  { label: "Cervejeiro", value: "BREWER" },
  { label: "Cervejaria", value: "BREWERY" },
];

class ChallengeRankingsEdit extends React.Component {
  componentDidMount() {
  }

  render() {
    this.item = store.edit != null ? store.edit : { user: {} };

    return (
      <EditDialog
        title={"Usuário"}
        opened={store.edit != null}
        onCancel={() => (store.edit = null)}
      >
        <div className="rowStyles">
          <div className="columnStyles">
            <TextField
              disabled
              errors={store.errors}
              field="name"
              item={this.item.user}
              label="Nome"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              errors={store.errors}
              field="email"
              item={this.item.user}
              label="E-mail"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              errors={store.errors}
              field="nick"
              item={this.item.user}
              label="Apelido"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Label color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <SelectField
              disabled
              errors={store.errors}
              field="producerType"
              item={this.item.user}
              label="&nbsp; Tipo de conta"
              list={producerType}
              style={styles.inputOutline}
              variant="outlined"
            />
          </div>
          <div className="columnStyles">
            <TextField
              disabled
              errors={store.errors}
              field="ranking"
              item={this.item}
              label="Ranking"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmojiEvents color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              errors={store.errors}
              field="points"
              item={this.item}
              label="Pontos"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmojiEvents color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              errors={store.errors}
              field="phone"
              item={this.item.user}
              label="Telefone"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </EditDialog>
    );
  }
}

export default observer(ChallengeRankingsEdit);

const styles = {
  inputOutline: {
    marginBottom: "20px",
  },
};

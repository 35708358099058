import { extendObservable, action } from "mobx";
import { get, jsonParser, post } from "../utils/Client";
import { SIZE } from "../utils/Constants";


export default class UserPremiumStore {
  constructor(sortColumn = "name") {
    const url = "/users";

    extendObservable(this, {
      queryField: "",

      sort: { column: sortColumn, ascending: true },

      size: SIZE,

      page: 0,

      count: 0,

      list: [],

      selected: [],

      edit: null,

      rules: {},

      errors: {},

      type: { label: "Somente Gratuito", value: "FREE" },

      producerFilter: null,

      premiumPlanType: null,

      expirationDate: "",

      open: action(() =>
        get(`${url}/list-premium/${this.selected[0].id}`)
          .then(jsonParser)
          .then((data) => (this.edit = data))
      ),

      query: action(() =>
        get(
            `${url}/list-premium` +
            `?query=${this.queryField}` +
            `&size=${this.size}` +
            `&page=${this.page}` +
            `&sort=${this.sort.column}` +
            `&order=${this.sort.ascending ? "ASC" : "DESC"}` + 
            `${(!!this.type && !! this.type.value) ? "&type=" + this.type.value : ""}`
        )
          .then(jsonParser)
          .then((data) => {
            this.page === 0
              ? (this.list = data.content)
              : this.list.push.apply(this.list, data.content);
            this.count = data.count;
          })
      ),

      grantPremium: action(() =>
        post(
          `${url}/grant-premium/${this.selected[0].id}`, 
          { 
            body: { 
              type: this.premiumPlanType,
              expirationDate: this.expirationDate.split("/").reverse().join("-") + "T12:00:00.000Z",
            } 
          }
        )
          .then(jsonParser)
          .then(() => {
            this.premiumPlanType = null;
            this.expirationDate = "";
            get(`${url}/list-premium/${this.selected[0].id}`)
            .then(jsonParser)
            .then((data) => (this.edit = data))
          })
      ),
    });
  }
}

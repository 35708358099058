import { observer } from "mobx-react";
import React from "react";
import { baseUrl } from "../../utils/Client";


class Visualizations extends React.Component {
  constructor(props){
    super(props);

    this.state = {
    };
  }

  render() {
    const { hopsStats, stats } = this.props;
    
    return (
      <div>
        <div className="rowStyles">
          <div className="columnStyles">
            <div style={styles.cardValue}>
              {`Visualizações do perfil: ${!!stats ? stats.visualizations : 0}`}
            </div>
            <div style={styles.cardValue}>
              {`Cliques no telefone: ${!!stats ? stats.phoneClicks : 0}`}
            </div>
          </div>
          <div className="columnStyles">
            <div style={styles.cardsRow}>
              { !!hopsStats && hopsStats.map((h, index) => h.enabled &&
                <div style={styles.card} key={`hop-${index}`}>
                  <img 
                    src={`${baseUrl}/img/hop/${h.hop.id}/200/200?count=${h.hop.imageCount}`}
                    alt="hop-img"
                    style={{ borderRadius: 100, width: 100, height: 100, alignSelf: "center" }}
                  />
                  <div style={styles.cardValue}>
                    {!!h.hop.title ? h.hop.title : "Sem nome"}
                  </div>
                  <div style={styles.cardValue}>
                    {`Visualizações: ${h.visualizations}`}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(Visualizations);

const styles = {
  inputOutline: {
    marginBottom: "20px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  btn: {
    fontSize: "12px",
    fontWeight: "700",
    color: "#ff9900",
  },
  cardsRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    marginTop: "20px",
  },
  imgLabel: {
    fontSize: "12px",
    color: "#777",
  },
  counter: {
    marginBottom: 10,
  },
  card: {
    backgroundColor: "#F2F2F2",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    paddingTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    minWidth: "300px",
  },
};

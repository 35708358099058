import { observer } from "mobx-react";
import React from "react";
import { Button, InputAdornment, Tab, Tabs } from "@material-ui/core";
import {
  AddCircle,
  CalendarToday,
  Description,
  EmojiEvents,
} from "@material-ui/icons";
import TextField from "../../components/TextField";
import EditDialog from "../../components/EditDialog";
import CheckBox from "../../components/CheckBox";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import { REQUIRED, hasErrors, validate } from "../../utils/Validation";
import ImageUpload from "../../components/ImageUpload";
import { baseUrl } from "../../utils/Client";
import OrganizerCard from "./OrganizerCard";
import Awards from "./Awards";


const store = Stores.load("hops-cups");

class Edit extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      organizerErrors: [],
      selectedTab: 0,
    };
  }

  componentDidMount() {
    store.rules = {
      name: REQUIRED,
      startDate: REQUIRED,
      validUntil: REQUIRED,
      description: REQUIRED, 
    };
  }

  save = async () => {
    try{
      var hasError = false;
      store.resetErrors();

      const organizerRules = {
        name: REQUIRED,
        description: REQUIRED,
      }
      const organizerErrors = [];
      
      if(!!this.item.organizers){
        for(const organizer of this.item.organizers){
          const error = validate(organizerRules, organizer);
          organizerErrors.push(error);
          hasError = hasError || hasErrors(error);
        }
        this.setState({ organizerErrors });
      }

      if(!hasError){
        await store.save(this.props.onSave);
        UIStore.redo(store);
        this.setState({ selectedTab: 0 });
      } else{
        UIStore.saveError("Erro ao salvar");
      }
    }catch(e){
      console.log(e.message ?? e);
      UIStore.saveError(e);
    }
  }

  render() {
    const { organizerErrors, selectedTab } = this.state;
    this.item = store.edit != null ? store.edit : {};
    
    return (
      <EditDialog
        title={this.item.id ? "Editar Desafio" : "Novo Desafio"}
        opened={store.edit != null}
        onCancel={() => {
          store.edit = null;
          this.setState({ selectedTab: 0 });
        }}
        onSave={this.save}
      >
        <div>
          <div className="rowStyles" style={{ marginTop: -30 }}>
            <Tabs 
              value={selectedTab} 
              onChange={(event, value) => this.setState({ selectedTab: value })} 
              aria-label="user tabs"
            >
              <Tab label="Geral" value={0} />
              <Tab label="Prêmiações" value={1} />
            </Tabs>
          </div>
          { selectedTab === 0 ?
            <div className="rowStyles">
              <div className="columnStyles">
                <TextField
                  errors={store.errors}
                  field="name"
                  item={this.item}
                  label="Nome"
                  placeholder="Nome"
                  style={styles.inputOutline}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmojiEvents color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
                <div style={styles.inputOutline}>
                  <div style={styles.imgLabel}>
                    {"Imagem Principal (1:1)"}
                  </div>
                  <ImageUpload
                    label="Imagem Principal (1:1)"
                    value={
                      !!this.item.homeImg
                        ? this.item.homeImg
                        : `${baseUrl}/img/hops-cup/${this.item.id}`
                    }
                    width={275}
                    height={275}
                    onChange={(photo) => (this.item.homeImg = photo)}
                  />
                </div>
                <div style={styles.inputOutline}>
                <div style={styles.imgLabel}>
                    {"Banner (2:1)"}
                  </div>
                  <ImageUpload
                    label="Banner (2:1)"
                    value={
                      !!this.item.bannerImg
                        ? this.item.bannerImg
                        : `${baseUrl}/img/hops-cup-banner/${this.item.id}`
                    }
                    width={275}
                    height={135}
                    onChange={(photo) => (this.item.bannerImg = photo)}
                  />
                </div>
                <TextField
                  errors={store.errors}
                  field="startDate"
                  item={this.item}
                  onChange={(e) => (this.item["startDate"] = e.target.value.formatDate())}
                  label="Data de início"
                  placeholder="DD/MM/AAAA"
                  style={styles.inputOutline}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarToday color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  errors={store.errors}
                  field="validUntil"
                  item={this.item}
                  onChange={(e) => (this.item["validUntil"] = e.target.value.formatDate())}
                  label="Data de finalização"
                  placeholder="DD/MM/AAAA"
                  style={styles.inputOutline}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarToday color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  errors={store.errors}
                  field="description"
                  item={this.item}
                  label="Descrição"
                  placeholder="Digite a descrição do evento. Esta seção aceita tags html"
                  rows={5}
                  style={styles.inputOutline}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Description color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
                { !!this.item.id &&
                  <div>
                    <CheckBox label="Liberado?" field="enabled" item={this.item} />
                  </div>
                }
              </div>
              <div className="columnStyles" style={{ marginLeft: 20, marginRight: 20 }}>
                <div style={styles.row}>
                  <h3>Organizadores</h3>
                  <Button
                    onClick={() => {
                      if(!this.item.organizers){
                        this.item.organizers = [];
                      }
                      this.item.organizers.push({ 
                        name: "",
                        description: "",
                        avatar: null,
                        enabled: true, 
                      });
                    }}
                    style={styles.btn}
                  >
                    <div style={styles.row}>
                      <AddCircle color="primary" fontSize="large" />
                      <div style={{ marginLeft: "10px" }}>Novo organizador</div>
                    </div>
                  </Button>
                </div>
                <div style={styles.cardsRow}>
                  { this.item?.organizers?.map((organizer, index) => organizer.enabled &&
                    <div key={`organizer-${index}`}>
                      <OrganizerCard
                        organizer={organizer}
                        onChange={(name, value) => {
                          organizer[name] = value;
                        }}
                        onDelete={() => {
                          if(!!organizer.id){
                            organizer.enabled = false;
                          } else{
                            this.item.organizers = this.item.organizers.filter(p => p !== organizer);
                          }
                        }}
                        errors={!!organizerErrors[index] ? organizerErrors[index] : {}}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            : selectedTab === 1 &&
            <div>
              <Awards 
                item={this.item}
              />
            </div>
          }
        </div>
      </EditDialog>
    );
  }
}

export default observer(Edit);

const styles = {
  inputOutline: {
    marginBottom: "20px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  btn: {
    fontSize: "12px",
    fontWeight: "700",
    color: "#ff9900",
  },
  cardsRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
  },
  imgLabel: {
    fontSize: "12px",
    color: "#777",
  }
};

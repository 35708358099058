import React from "react";
import { observer } from "mobx-react";
import { MenuItem, Menu } from "@material-ui/core";

import TuneIcon from "@material-ui/icons/Tune";

class SelectPanel extends React.Component {
  state = { anchorEl: null };

  handleOpen = (event) => this.setState({ anchorEl: event.currentTarget });

  handleSelect = (o) => {
    let { store, entity } = this.props;
    store[entity] = o;
    //store.list = [];
    store.query();
    this.setState({ anchorEl: null });
  };

  render() {
    let { options, store, entity, label, getLabel, hideNull } = this.props;
    let { anchorEl } = this.state;

    return (
      <>
        <div
          style={{
            width: "100%",
            margin: "2px 10px 0 0",
            lineHeight: "35px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
          onClick={this.handleOpen}
        >
          <strong>{label}:</strong> <TuneIcon style={{ marginLeft: "5px" }} />{" "}
          {getLabel(store[entity])}
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => this.setState({ anchorEl: null })}
        >
          { !hideNull &&
            <MenuItem key="k-1" onClick={() => this.handleSelect(null)}>
              {getLabel(null)}
            </MenuItem>
          }
          {options.map((o, i) => (
            <MenuItem key={`k${i}`} onClick={() => this.handleSelect(o)}>
              {getLabel(o)}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

export default observer(SelectPanel);

import { observer } from "mobx-react";
import React from "react";
import EditDialog from "../../components/EditDialog";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import { REQUIRED } from "../../utils/Validation";
import { baseUrl, del, jsonParser } from "../../utils/Client";
import { Button } from "@material-ui/core";
import dayjs from "dayjs";


const store = Stores.load("account-deletion");

class UserEdit extends React.Component {
  state = {
    submitting: false,
  }

  componentDidMount() {
    store.rules = {
      name: REQUIRED,
      email: REQUIRED,
      role: REQUIRED,
    };
  }

  removeAccount = () => {
    this.setState({ submitting: true }, async () => {
      try{
        await del(`/account-deletion/delete-account/${this.item.id}`).then(jsonParser);
        store.edit = null;
        UIStore.redo(store);
      } catch(e){
        console.log(e.message ?? e);
        UIStore.saveError();
      }
    })
  }

  render() {
    this.item = store.edit != null ? store.edit : {};

    return (
      <EditDialog
        title="Pedido de Cancelamento de Conta"
        opened={store.edit != null}
        onCancel={() => (store.edit = null)}
      >
        <div className="rowStyles">
          <div className="columnStyles">
            <div style={styles.keyVal}>
              {`Email: ${this.item.email}`}
            </div>
            <div style={styles.keyVal}>
              {`Nome: ${this.item.name}`}
            </div>
            <div style={styles.keyVal}>
              {`Motivo: ${this.item.descriptiona ?? "Não informado"}`}
            </div>
            <div style={styles.keyVal}>
              {`Data do pedido: ${dayjs(this.item.createdDate).format("DD/MM/YYYY HH:mm")}`}
            </div>
          </div>
          <div className="columnStyles">
            { !!this.item.user ? (
                <>
                  <img 
                    src={`${baseUrl}/img/avatar/${this.item.user.id}/200/200?count=${this.item.user.avatarCount}`} 
                    style={styles.avatar}
                    alt="avatar"
                    width={100}
                    height={100}
                  />
                  <div style={styles.keyVal}>
                    {`Documento: ${this.item.user.document ?? "Não informado"}`}
                  </div>
                  <div style={styles.keyVal}>
                    {`Data de Nascimento: ${!!this.item.user.birthday ? this.item.user.birthday.split("-").reverse().join("/") : "Não informado"}`}
                  </div>
                  <div style={styles.keyVal}>
                    {`Telefone: ${this.item.user.phone ?? "Não informado"}`}
                  </div>
                  { this.item.user.premium ?
                    <div style={styles.keyVal}>
                      {`Usuário possui uma conta premium. O pagamento das mensalidades deve ser cancelado pelo usuário no app do PlayStore ou AppStore antes que a conta possa ser cancelada!`}
                    </div>
                    : (
                      <>
                        <div style={styles.keyVal}>
                          {`Entre em contato pelo email ${this.item.user.email}`}
                        </div>
                        <div style={styles.keyVal}>
                          {`e confirme a identidade do usuário antes de remover a conta.`}
                        </div>
                        <Button
                          style={styles.btn}
                          onClick={this.removeAccount}
                        >
                          Remover conta de usuário
                        </Button>
                      </>
                    )
                  }
                </>
              ) : (
                <>
                  <div style={styles.keyVal}>
                    {`Não foi encontrado nenhum usuário com o email ${this.item.email}`}
                  </div>
                </>
              )
            }
            
          </div>
        </div>
      </EditDialog>
    );
  }
}

export default observer(UserEdit);

const styles = {
  keyVal: {
    fontSize: 14,
    fontWeight: "500",
    color: "#000",
  },
  avatar: {
    width: 100,
    height: 100,
    borderRadius: 100,
    marginBottom: 20,
    alignSelf: "center",
  },
  btn: {
    backgroundColor: "#FF9900",
    marginTop: 20,
  },
};

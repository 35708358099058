import { Modal } from "@material-ui/core";
import React from "react";
import SearchField from "../../components/SearchField";
import UIStore from "../../stores/UIStore";
import Stores from "../../stores/Stores";
import List from "../../components/List";
import SortPanel from "../../components/SortPanel";
import { observer } from "mobx-react";


const store = Stores.load("users");

class SelectUserModal extends React.Component {
  componentDidMount() {
    UIStore.init("Usuários", store);
  }

  componentDidUpdate(prevProps, prevState){
    const { open } = prevProps;

    if(open !== this.props.open){
      store.page = 0;
      store.searchAll = false;
      store.queryField = "";
      store.query();
    }
  }

  render() {
    const { open, onClose, onSelect } = this.props;

    return(
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="select-user-modal"
        aria-describedby="modal for user selection"
        style={{
          height: "100%",
          overflow: "scroll",
        }}
      >
        <List
          onClick={(item) => onSelect(item)}
          store={store}
          data={[ ...[null] , ...store.list ].map((i) => ({
            item: i,
            title: !!i ? (!!i.name ? i.name : "Sem nome") + " (" + i.email + ")" : "Todos",
            content: (
              !!i ?
                <>
                  Adicionado em:{" "}
                  {i.createdDate ? i.createdDate.formatTimeStamp() : ""}
                </>
              : <></>
            ),
          }))}
          status={[ ...[null] , ...store.list ].map((i) => ({ id: i?.id, label: "", class: "" }))}
          rightPanel={
            <>
              <SortPanel
                store={store}
                filters={{
                  name: "Nome",
                  email: "Email",
                  createdDate: "Data de Adição",
                }}
              />
              <SearchField store={store} />
            </>
          }
        />
      </Modal>
    )
  }
}

export default observer(SelectUserModal);

import { observer } from "mobx-react";
import React from "react";
import EditDialog from "../../components/EditDialog";
import CheckBox from "../../components/CheckBox";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";


const store = Stores.load("help-requests");

class UserEdit extends React.Component {
  componentDidMount() {
    store.rules = {};
  }

  save = () =>
    store
      .save(this.props.onSave)
      .then(() => UIStore.redo(store))
      .catch(UIStore.saveError);

  render() {
    this.item = store.edit != null ? store.edit : {};

    return (
      <EditDialog
        title={"Editar pedido"}
        opened={store.edit != null}
        onCancel={() => (store.edit = null)}
        onSave={this.save}
      >
        <div className="rowStyles">
          <div className="columnStyles">
            <div>{`Nome: ${this.item.name}`}</div>
            <div>{`Email: ${this.item.email}`}</div>
            <div>{`Descrição: ${this.item.description}`}</div>
            <div>{`Data: ${this.item.createdDate?.formatTimeStamp()}`}</div>
            <div>
              <CheckBox label="Liberado?" field="enabled" item={this.item} />
            </div>
          </div>
        </div>
      </EditDialog>
    );
  }
}

export default observer(UserEdit);


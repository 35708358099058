import { observer } from "mobx-react";
import React from "react";
import { Button, InputAdornment } from "@material-ui/core";

import { Label, Tv } from "@material-ui/icons";

import TextField from "../../components/TextField";
import ImageUpload from "../../components/ImageUpload";
import EditDialog from "../../components/EditDialog";

import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import { REQUIRED } from "../../utils/Validation";
import SelectField from "../../components/SelectField";
import { baseUrl } from "../../utils/Client";

const store = Stores.load("contents", "createdDate");

class ContentEdit extends React.Component {
  
  componentDidMount() {
    store.rules = {
      title: REQUIRED,
    };
  }
 
  save = () => 
    store
      .save(this.props.onSave)
      .then(() => UIStore.redo(store))
      .catch(UIStore.saveError);

  getVideoCode = () => {
    const videoURL = !!this.item.videoURL ? this.item.videoURL.trim() : "";
    if(!!videoURL && (videoURL.startsWith("https:") || videoURL.startsWith("http:"))){
      const splitUrl = videoURL.split("?");
      if(splitUrl.length === 2){
        const urlParams = new URLSearchParams(splitUrl[1]);
        if(urlParams.has("v")){
          return urlParams.get("v");
        }
      }

      return "";
    }

    return videoURL;
  }

  render() {
    this.item =
      store.edit != null
        ? store.edit
        : { title: "", type: "LINK", enabled: true };

    return (
      <EditDialog
        title={this.item.id ? "Editar Banner" : "Novo Banner"}
        opened={store.edit != null}
        onCancel={() => (store.edit = null)}
        onSave={async () => {
          if(this.item.type === "VIDEO"){
            this.item.videoURL = this.getVideoCode();
            if(!this.item.videoURL){
              return;
            }
          }
          await this.save();
        }}
        size="lg"
      >
        <div className="rowStyles">
          <div className="columnStyles" style={{ width: "30%" }}>
            <TextField
              errors={store.errors}
              field="title"
              item={this.item}
              label="Título"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Label color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <SelectField
              errors={store.errors}
              field="type"
              item={this.item}
              label="Tipo"
              list={[
                { value: "TEXT", label: "Texto" },
                { value: "VIDEO", label: "Vídeo" },
                { value: "NEWS", label: "News" },
              ]}
              style={styles.inputOutline}
              variant="outlined"
            />
            {this.item.type === "VIDEO" && (
              <>
                <TextField
                  errors={store.errors}
                  field="videoURL"
                  item={this.item}
                  label="Código YouTube (watch?v=<CÓDIGO>)"
                  style={styles.inputOutline}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Tv color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          </div>
          <div className="columnStyles">
            {(this.item.type === "TEXT" || this.item.type === "NEWS") ? (
              <ImageUpload
                label="Imagem"
                value={
                  this.item.image
                    ? this.item.image
                    : baseUrl + "/img/content/" + this.item.id
                }
                width={375}
                height={200}
                onChange={(photo) => (this.item.image = photo)}
              />
            ) : (
              <img
                alt="thumb"
                src={`https://img.youtube.com/vi/${this.getVideoCode()}/0.jpg`}
                style={{ width: "375px" }}
              />
            )}
          </div>
          <div className="columnStyles" style={{ width: "30%" }}>
            <TextField
              errors={store.errors}
              field="text"
              item={this.item}
              label="Texto"
              style={styles.inputOutline}
              variant="outlined"
              rows={10}
            />
            { this.item.type === "NEWS" &&
              <div>
                <Button 
                  onClick={() => this.item.text += (!!this.item.text ? "\n" : "") + "<p>INSIRA_TEXTO_AQUI</p>"}
                >
                  Novo parágrafo
                </Button>
                <Button 
                  onClick={() => this.item.text += (!!this.item.text ? "\n" : "") + '<a href="INSIRA_URL_AQUI" target="_blank">INSIRA_TEXTO_AQUI</a>'}
                >
                  Novo link
                </Button>
              </div>
            }
          </div>
        </div>
      </EditDialog>
    );
  }
}

export default observer(ContentEdit);

const styles = {
  inputOutline: {
    marginBottom: "20px",
  },
};

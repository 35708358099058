import React from "react";
import { observer } from "mobx-react";
import List from "../../components/List";
import AuthenticationStore from "../../stores/AuthenticationStore";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import Edit from "./Edit";
import { get, jsonParser } from "../../utils/Client";
import SelectPanel from "../../components/SelectPanel";


const store = Stores.load("challenge-rankings");
const producerType = {
  BREWER: "Cervejeiro",
  BREWERY: "Cervejaria",
};
const PRODUCER_OPTIONS = [
  { label: "Todos", value: null },
  { label: "Somente Cervejeiros", value: "BREWER" },
  { label: "Somente Cervejarias", value: "BREWERY" },
];
store.producerFilter = PRODUCER_OPTIONS[0];

class ChallengeRankings extends React.Component {
  state = {
    challenges: [],
  };

  componentDidMount() {
    get("/challenge-groups/all")
      .then(jsonParser)
      .then(res => {
        store.challengeGroup = { label: res[0].name, value: res[0].id };
        this.setState({ challenges: res.map(c => ({ label: c.name, value: c.id })) });
        UIStore.init("Rankings", store);
      })
  }

  render() {
    let canEdit;
    if (AuthenticationStore.me.role === "MANAGER") {
      canEdit = false;
    } else {
      canEdit = true;
    }

    return (
      <>
        <List
          store={store}
          data={store.list.map((i) => ({
            item: i,
            title: (!!i.user.name ? i.user.name : "Sem nome") + " (" + i.user.email + ")",
            content: (
              <>
                Ranking:{" "}
                {i.ranking ? i.ranking : ""}
                <br />
                Pontos:{" "}
                {!!i.points ? i.points : 0}
                <br />
                Tipo de conta: {" "}
                {!!i.user.producerType ? producerType[i.user.producerType] : "Não definido"}
              </>
            ),
          }))}
          status={{}}
          leftPanel={
            <>
              <SelectPanel
                store={store}
                options={PRODUCER_OPTIONS}
                label="Tipo de conta"
                getLabel={(item) => item?.label}
                entity="producerFilter"
                hideNull={true}
              />
            </>
          }
          rightPanel={
            <>
              <SelectPanel
                store={store}
                options={this.state.challenges}
                label="Desafio"
                getLabel={(item) => item?.label}
                entity="challengeGroup"
                hideNull={true}
              />
            </>
          }
          onEdit={canEdit ? () => store.open() : null}
        />
        <Edit />
      </>
    );
  }
}

export default observer(ChallengeRankings);

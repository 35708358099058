import { observer } from "mobx-react";
import React from "react";
import { Button } from "@material-ui/core";
import EditDialog from "../../components/EditDialog";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import { REQUIRED } from "../../utils/Validation";
import { baseUrl, get, jsonParser, post } from "../../utils/Client";

const store = Stores.load("forum-reports", "id");

class ForumReportEdit extends React.Component {
  state = {
    reportId: null,
    topicPosts: [],
    loading: false,
  }

  componentDidMount() {
    store.rules = {
      name: REQUIRED,
      email: REQUIRED,
      role: REQUIRED,
    };

  }
  
  componentDidUpdate(){
    const { reportId } = this.state;
    this.item = store.edit != null ? store.edit : {};

    if(reportId !== this.item.id){
      this.setState({ reportId: this.item.id, loading: true }, async () => {
        if(!!this.item.topic){
          const res = await get(`/topics/posts/${this.item.topic.id}?page=0&size=999999&sort=createdDate&order=ASC`).then(jsonParser);
          this.setState({ topicPosts: res.content, loading: false });
        } else{
          this.setState({ topicPosts: [], loading: false });
        }
      });
    }
  }

  save = () =>
    store
      .save(this.props.onSave)
      .then(() => UIStore.redo(store))
      .catch(UIStore.saveError);


  renderCard = (content, flagged) => {
    return(
      <div style={flagged ? styles.flaggedCard : styles.card}>
        { flagged && <div style={styles.flaggedLabel}>Conteúdo denunciado!</div> }
        <div style={styles.row}>
          <div>
            <img 
              src={`${baseUrl}/img/avatar/${content.author.id}/256/256?${Math.random()}`} 
              alt="user-avatar"
              style={styles.avatar}  
            />
            <div style={styles.name}>{content.author.name}</div>
            <div style={styles.nick}>{!!content.author.nick ? content.author.nick : ""}</div>
          </div>
          <div>
            { !!content.title && <div style={styles.title}>{`${content.title}`}</div> }
            <div style={styles.text}>{`${content.text}`}</div>
          </div>
        </div>
      </div>
    )
  }

  removeContent = async () => {
    await post(`/forum-reports/remove-content/${this.item.id}`).then(jsonParser);
    UIStore.redo(store);
    store.edit = null;
  }

  render() {
    this.item = store.edit != null ? store.edit : {};

    return (
      <EditDialog
        title="Denúncia"
        opened={store.edit != null}
        onCancel={() => (store.edit = null)}
      >
        <div className="rowStyles">
          <div style={{ ...styles.name, ...styles.row}}>
            {`Motivo: da denúncia `}
            <div style={styles.nick}>{`${this.item.text}`}</div>
          </div>
          <Button onClick={this.removeContent} style={styles.btn}>
            Remover conteúdo
          </Button>
        </div>
        <div className="rowStyles">
          <div className="columnStyles" style={{ marginRight: 10, flex: 1 }}>
            <div style={styles.type}>Tópico</div>
            { !!this.item.topic && this.renderCard(this.item.topic, !this.item.topicPost ) }
          </div>
          <div className="columnStyles" style={{ marginLeft: 10, flex: 1 }}>
            <div style={styles.type}>Respostas</div>
            { this.state.topicPosts.map((tp, index) =>
              <div style={{ marginBottom: 10 }} key={`post-${index}`}>
                { this.renderCard(tp, !!this.item.topicPost && this.item.topicPost.id === tp.id) }
              </div>
            )}
          </div>
        </div>
      </EditDialog>
    );
  }
}

export default observer(ForumReportEdit);

const styles = {
  card: {
    borderWidth: 1,
    borderRadius: 16,
    borderColor: "#999",
    borderStyle: "solid",
    backgroundColor: "#DEDEDE",
    padding: 8,
    display: "flex",
    flexDirection: "column",
  },
  flaggedCard: {
    borderWidth: 1,
    borderRadius: 16,
    borderColor: "#999",
    borderStyle: "solid",
    backgroundColor: "#FF0",
    padding: 8,
    display: "flex",
    flexDirection: "column",
  },
  flaggedLabel: {
    fontWeight: "700",
    fontSize: 18,
    color: "#F00",
    textAlign: "center",
    marginBottom: 8,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
  avatar: {
    width: 128,
    height: 128,
    borderRadius: 128,
  },
  name: {
    fontWeight: "700",
    fontSize: 14,
  },
  nick: {
    fontWeight: "500",
    fontSize: 14,
    color: "#666",
  },
  type: {
    fontWeight: "700",
    fontSize: 18,
    marginBottom: 20,
  },
  title: {
    fontWeight: "700",
    fontSize: 16,
  },
  text: {
    fontWeight: "500",
    fontSize: 14,
  },
  btn: {
    fontWeight: "700",
    fontSize: 18,
    color: "#F00",
    backgroundColor: "#DEDEDE",
  },
};

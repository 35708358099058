import { extendObservable, action } from "mobx";
import { post, get, put, del, jsonParser } from "../utils/Client";
import { SIZE } from "../utils/Constants";
import dayjs from "dayjs";


export default class HopsCupStore {
  constructor(entity, sortColumn = "name") {
    const url = `/${entity}`;

    extendObservable(this, {
      queryField: "",

      searchAll: false,

      sort: { column: sortColumn, ascending: true },

      size: SIZE,

      page: 0,

      count: 0,

      list: [],

      selected: [],

      edit: null,

      rules: {},

      errors: {},

      authorId: null,

      open: action(() =>
        get(url + `/${this.selected[0].id}`)
          .then(jsonParser)
          .then((data) => {
            data.startDate = dayjs(data.startDate).format("DD/MM/YYYY");
            data.validUntil = dayjs(data.validUntil).format("DD/MM/YYYY");
            this.edit = data;
          })
      ),

      new: action((empty = {}) => (this.edit = empty)),

      create: action(async () => {
        const body = {
          ...this.edit,
          ...{
            startDate: this.edit.startDate.split("/").reverse().join("-") + "T12:00:00.000-03:00",
            validUntil: this.edit.validUntil.split("/").reverse().join("-") + "T12:00:00.000-03:00",
          }
        };
        await post(url, { body: body }).then(jsonParser)
      }),

      update: action(async () => {
        const body = {
          ...this.edit,
          ...{
            startDate: this.edit.startDate.split("/").reverse().join("-") + "T12:00:00.000-03:00",
            validUntil: this.edit.validUntil.split("/").reverse().join("-") + "T12:00:00.000-03:00",
          }
        };
        await put(url + `/${this.edit.id}`, { body: body }).then(jsonParser)
      }),

      resetErrors: action(() =>
        Object.keys(this.rules).forEach((k) => delete this.errors[k])
      ),

      validate: action(() =>
        Object.keys(this.rules).forEach((k) => {
          const updateError = (rule) => {
            let msg = rule(k, this.edit);
            if (msg) {
              this.errors[k] = msg;
            }
          };
          if (Array.isArray(this.rules[k])) {
            this.rules[k].forEach(updateError);
          } else {
            updateError(this.rules[k]);
          }
        })
      ),

      save: action(
        (onSave) =>
          new Promise((resolve, reject) => {
            this.resetErrors();
            this.validate();
            if (Object.keys(this.errors).length > 0) {
              reject("Erro de validação.");
            } else if (this.edit.id != null) {
              return this.update().then((data) => {
                if (onSave) {
                  onSave(data);
                }
                resolve(data);
              });
            } else {
              return this.create().then((data) => {
                if (onSave) {
                  onSave(data);
                }
                resolve(data);
              });
            }
          })
      ),

      query: action(() =>
        get(
          url +
            `?query=${this.queryField}` +
            `&all=${this.searchAll}` +
            `&size=${this.size}` +
            `&page=${this.page}` +
            `&sort=${this.sort.column}` +
            `&order=${this.sort.ascending ? "ASC" : "DESC"}` + 
            `${!!this.authorId ? "&authorId=" + this.authorId : ""}`
        )
          .then(jsonParser)
          .then((data) => {
            this.page === 0
              ? (this.list = data.content)
              : this.list.push.apply(this.list, data.content);
            this.count = data.count;
          })
      ),

      delete: action(
        () =>
          new Promise((resolve, reject) =>
            this.selected.map((i) =>
              del(url + `/${i.id}`)
                .then((res) => this.selected.shift())
                .catch((e) => reject(e))
                .then(() => {
                  if (this.selected.length === 0) resolve();
                })
            )
          )
      ),
    });
  }
}

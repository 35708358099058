import React from "react";
import { observer } from "mobx-react";
import List from "../../components/List";
import AuthenticationStore from "../../stores/AuthenticationStore";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import Edit from "./Edit";
import SearchField from "../../components/SearchField";
import SortPanel from "../../components/SortPanel";


const store = Stores.load("account-deletion", "createdDate");

class AccountDeletionRequest extends React.Component {
  componentDidMount() {
    UIStore.init("AccountDeletionRequest", store);
  }

  handleDelete = () => {
    let count = store.selected.length;

    UIStore.showConfirmDialog(
      `Deseja remover ${count} pedidos?`,
      "Remover Pedidos"
    ).then((b) => {
      if (b) {
        store
          .delete()
          .then(() => store.query())
          .catch(() => alert("Erro!"));
      }
    });
  };

  handleFilter = () => {
    store.page = 0;
    store.searchAll = !store.searchAll;
    store.query();
  };

  render() {
    let canEdit;
    if (AuthenticationStore.me.role === "MANAGER") {
      canEdit = false;
    } else {
      canEdit = true;
    }

    return (
      <>
        <List
          store={store}
          data={store.list.map((i) => ({
            item: i,
            title: (!!i.name ? i.name : "Sem nome") + " (" + i.email + ")",
            content: (
              <>
                Adicionado em:{" "}
                {i.createdDate ? i.createdDate.formatTimeStamp() : ""}
              </>
            ),
          }))}
          status={[]}
          rightPanel={
            <>
              <SortPanel
                store={store}
                filters={{
                  name: "Nome",
                  email: "Email",
                  createdDate: "Data de Adição",
                }}
              />
              <SearchField store={store} />
            </>
          }
          onEdit={canEdit ? () => store.open() : null}
          onDelete={canEdit ? () => this.handleDelete() : null}
        />

        <Edit />
      </>
    );
  }
}

export default observer(AccountDeletionRequest);
